<mat-toolbar color="accent">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>home</mat-icon>
    </button>
    <span>Young Carers Assessment</span>
    <span class="example-spacer"></span>
    
    <!-- <img src="../../assets/images/sfcypsLogo.png" height="100px"/> -->
  </mat-toolbar>

  
<form *ngIf="!this.completedRef" [formGroup]="this.ypsAssessmentForm">
<div class="container-fluid box-80-width">
  <br><p><b>Self-Assessment. </b>This assessment will take around 15 minutes to complete. Your answers won't be saved until you submit the form at the end.</p>

	<div class="row">
		<div class="col-md-4">
      <mat-form-field appearance="outline" class="box-full-width" >
        <mat-label>First name</mat-label>
        <input matInput formControlName="firstName" placeholder="First Name" required>
      </mat-form-field>
		</div>
		<div class="col-md-4">
      <mat-form-field appearance="outline" class="box-full-width">
        <mat-label>Last name</mat-label>
        <input matInput formControlName="lastName" required>
      </mat-form-field>
		</div>
		<div class="col-md-4">
      <mat-form-field  appearance="outline" class="box-full-width">
        <mat-label >Date of birth</mat-label>
        <input  matInput [formControl]="dobControl"  placeholder="DD/MM/YYYY" [matDatepicker]="picker" required (ngModelChange)="term$.next($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
		</div>
    <div class="col-md-12">

      <p>
        Suffolk Family Carers will not share information about you with anyone without your permission unless we have  a safeguarding concern. 
        <!-- <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">View more details here.</a> -->
      </p>
      
    </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="box-full-width">
          <mat-label>Gender</mat-label>
          <mat-select  #genderSelect matInput id="genderSelect" formControlName="gender">
            <mat-option *ngFor="let item of genders" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="box-full-width">
          <mat-label>Education Status</mat-label>
          <mat-select  #educationStatusSelect matInput id="educationStatusSelect" formControlName="educationStatus" >
            <mat-option *ngFor="let item of educationStatusList" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="box-full-width">
          <mat-label>School / college attending</mat-label>
          <input matInput formControlName="schoolCollege" >
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-12">
        <label><b>Young Carer Needs Assessment – Data Sharing</b></label><br>
        <p>Suffolk Family Carers carry out statutory Young Carer Needs Assessments on behalf of Suffolk County Council. More information about Young Carer Needs Assessments can be found <a href="https://www.carersuk.org/help-and-advice/practical-support/getting-care-and-support/young-carers-and-carers-of-children-under-18" target="_blank">here.</a>  
          <br><br>
          The Children and Families Act 2014 states that young carers have a legal right to a young carers assessment. The purpose of the assessment is to identify individual needs and how the caring role affects the wellbeing of the young person. The assessment recognises the strengths of the family as well as identifying challenges faced by its members. The wishes, feelings and aspirations of the young person are at the centre of our Young Carers Needs Assessment. </p>
        <p>In order for us to carry out a Young Carer Needs Assessment we need your permission to share the following data with Suffolk County Council.			</p>
        <ul>
            <li>Young person’s name</li>
            <li>Young person’s address</li>
            <li>Young person’s date of birth</li>
            <li>Young person’s gender</li>
            <li>Name of the cared for person</li>
            <li>A summary of your completed assessment</li>
        </ul>
     
          <mat-radio-group class="row-radio-group"  #consentYcStatutoryAssessment id="consentYcStatutoryAssessment" formControlName="statAssessmentOptIn" > 
            <mat-radio-button class="row-radio-button" [value]="true"><span class="wrap-mat-radio-label">YES, I would like to request a statutory Young Carer Needs Assessment and I am aware that the above information will be shared with Suffolk County Council</span></mat-radio-button><br>
            <mat-radio-button class="row-radio-button" [value]="false"><span class="wrap-mat-radio-label">NO, I do not wish to request a statutory Young Carer Needs Assessment</span></mat-radio-button>
          </mat-radio-group> 
      </div>
    </div> -->
    <br>
    <div class="row">
    <div class="col-md-12">
      <b>Below are jobs that some carers do to help. Please read each one and select the box to show how often you have done each of the jobs in the last month.</b><br>
      <br>
    </div>
    <hr>
    
    <div class="col-md-6 question-padding">
      <label>1. Clean your own bedroom</label>
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca1" aria-label="Font Style" class="fit-width" type="number" required>
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
      
    </div><hr>
    <div class="col-md-6">
      <label>2. Clean other rooms</label><br>
      
      
    </div>
    <div class="col-md-6  question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca2" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>3. Wash up dishes or put dishes in a dishwasher</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca3" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>4. Decorate rooms</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca4" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>   <hr>
     <div class="col-md-6">
      <label>5. Take responsibility for shopping for food</label><br>
      
      
    </div>
    <div class="col-md-6  question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca5" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6 question-padding">
      <label>6. Help with lifting or carrying heavy things</label><br>
    
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca6" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>7. Help with financial matters such as dealing with bills, banking money, collecting benefits</label><br>
     
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca7" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div> <hr>
       <div class="col-md-6">
      <label>8. Work part time to bring money in</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca8" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div> <hr>
       <div class="col-md-6">
      <label>9. Interpret, sign or use another communication system
        for the person you care for</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca9" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>   <hr>
     <div class="col-md-6">
      <label>10. Help the person you care for to dress or undress</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca10" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>11. Help the person you care for to have a wash</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca11" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>   <hr>
     <div class="col-md-6">
      <label>12. Help the person you care for to have a bath or shower</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca12" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>13. Keep the person you care for company e.g. sitting with them,
        reading to them, talking to them</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca13" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>14. Keep an eye on the person you care for to make sure they are alright</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca14" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>15. Take the person you care for out e.g. for a walk or to see friends or relatives</label><br>
     
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca15" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>16. Take brothers or sisters to school</label><br>
     
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca16" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>  <hr>
      <div class="col-md-6">
      <label>17. Look after brothers or sisters whilst another adult is near by</label><br>
     
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca17" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div> <hr>
       <div class="col-md-6">
      <label>18. Look after brothers or sisters on your own</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="maca18" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>
    <hr>
  </div>
  <div class="row">
    <div class="col-md-12">
      <b>How does your caring role make you feel? Please read each statement and select the box to show how often this is true for you. There are no right or wrong answers.</b>
    <br><br>
    </div>
    <div class="col-md-12">
      
    </div>
    <hr>
    <div class="col-md-6">
      <label>1. Because of caring I feel I am doing something good</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc1" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>2. Because of caring I feel that I am helping</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc2" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>3. Because of caring I feel closer to my family</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc3" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>4. Because of caring I feel good about myself</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc4" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>5. Because of caring I have to do things that make me upset</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc5" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>6. Because of caring I feel stressed</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc6" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>7. Because of caring I feel that I am learning useful things</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc7" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>8. Because of caring my parents are proud of the kind of person I am</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc8" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>9. Because of caring I feel like running away</label><br>
     
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc9" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>10. Because of caring I fee l very lonely</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc10" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>11. Because of caring I feel like I can’t cope</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc11" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>12. Because of caring I can’t stop thinking about what I have to do</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc12" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>13. Because of caring I feel so sad I can hardly stand it</label><br>
     
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc13" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>14. Because of caring I don't think I matter</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc14" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>15. Because of caring I like who I am</label><br>
     
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc15" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>16. Because of caring life doesn’t seem worth living</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc16" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>17. Because of caring I have trouble staying awake</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc17" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>18.Because of caring I feel I am better able to cope with problems</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc18" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>19. I feel good about helping</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc19" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
    <div class="col-md-6">
      <label>20. Because of caring I feel I am useful</label><br>
      
      
    </div>
    <div class="col-md-6 question-padding">
      <section>
        <mat-button-toggle-group formControlName="panoc20" aria-label="Font Style" class="fit-width" >
          <mat-button-toggle value=0>Never</mat-button-toggle>
          <mat-button-toggle value=1>Some of the time</mat-button-toggle>
          <mat-button-toggle value=2>A lot of the time</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div><hr>
  </div>
    
   
    <div class="row">
      <div class="col-md-12">
        <b>Answering the following three questions will help us to understand more about you and your caring role and the reason for you making contact with our service.</b>
      <br><br>
      </div>
      <br><br>
      <div class="col-md-12">
        <label>Information I want to share about myself: (eg. Interests, education, work, family, feelings)</label>
        <mat-form-field appearance="outline" class="box-full-width">
          <textarea matInput formControlName="q1InfoToShare" ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <label>I think I am a young carer/young adult carer because…</label>
        <mat-form-field appearance="outline" class="box-full-width">
          <textarea matInput formControlName="q2IThinkBecause" ></textarea>
        </mat-form-field>
      </div>
      
      <div class="col-md-12">
        <label>What change(s) I would like support to make</label>
        <mat-form-field appearance="outline" class="box-full-width">
          <textarea matInput formControlName="q4Changes" ></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>This is a new online form we have recently created.  What do you think of it?</label>
        <mat-form-field appearance="outline" class="box-full-width">
          <textarea matInput formControlName="formComments" ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <div *ngIf="this.ypsAssessmentForm.invalid" >
          <br>
           <b>Please complete all required fields before continuing.</b> 
           <br><br>
        </div>
        <button [disabled]="this.saving || this.ypsAssessmentForm.invalid" type="submit" mat-raised-button color="accent" (click)="submitAssessment()" >{{this.saveButtonText}}</button>
        <br><br>
      </div>
      
    </div>


</div>

<br>

</form>
<!-- <pre > {{ypsAssessmentForm.value | json}} </pre> -->
<div *ngIf="this.completedRef">
  <div
  fxLayout="row"
  fxLayout.xs="column" 
  fxFlexFill
  fxLayoutAlign="center center"
 >
 <div fxFlex="50">
   <br>
       <h2>Thank you for completing your assessment.<br>
           <small>Your confirmation reference is: {{this.completedRef + 1000}} </small></h2>
           <br>
           <br>
           <p>An Adviser will contact you within four weeks to arrange a follow up conversation to discuss the support you would like. 
            For more information about our team or service please visit <a href="https://suffolkfamilycarers.org/young-carers/" target="_parent">Young Carers | Suffolk Family Carers</a>
            </p>
           <br>
           <br>
           <a mat-raised-button color="primary" href="https://suffolkfamilycarers.org/young-carers/" target="_parent">Continue to homepage</a>
   </div>
</div>




