<form [formGroup]="carerForm">
  <br>
  <div *ngIf="this.carerForm.get">

  </div>
  <p>Consent for <b>{{this.carerForm.get('firstName').value}} {{this.carerForm.get('lastName').value}}</b></p>
    <div id="consentProYc" *ngIf="(this.whoReferring === 'another'  && (relationship === 2330 || relationship === 2331 || relationship === 2358) && this.carerForm.get('age').value < 18)"  fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
      <!-- Done! -->
      <div fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
        <label><b>Consent for Suffolk Family Carers to hold personal details</b></label><br>
        <mat-checkbox [ngStyle]="{'color': (this.carerForm.get('consentStoreData').value !== true && this.showErrors)  ? 'red' : ''}" labelPosition="right"   formControlName="consentStoreData">Yes, I confirm I have the consent of this child's parent or guardian to provide Suffolk Family Carers with the child's personal details, and for them to be held as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a></mat-checkbox>
        <br><br>
        <label><b>Consent for Suffolk Family Carers to share personal details</b></label><br>
        <mat-checkbox labelPosition="right"   formControlName="consentShareData">Yes, I confirm I have the consent of this child's parent or guardian for Suffolk Family Carers to share the child's personal details as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a>, and that the parent/guardian is aware of how they may be used</mat-checkbox>
        <br><br>
        <label><b>Allowed Support Contact Methods for Support</b></label><br>
        I confirm I have the consent of this person for  Suffolk Family Carers to contact them  via the following methods to discuss Support
        <ul>
            <mat-checkbox labelPosition="right"   formControlName="consentContactTelephone">Telephone</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactEmail">E-mail</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactSms">SMS text message</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactPost">Post</mat-checkbox><br>
           </ul>
           <br>
      </div>

        <div *ngIf="(this.carerForm.get('age').value < 18 && this.carerForm.get('age').value > 4)" fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
          <label><b>Young Carer Needs Assessment – Data Sharing</b></label><br>
          <p>Suffolk Family Carers carry out statutory Young Carer Needs Assessments on behalf of Suffolk County Council. More information about Young Carer Needs Assessments can be found <a href="https://www.carersuk.org/help-and-advice/practical-support/getting-care-and-support/young-carers-and-carers-of-children-under-18" target="_blank">here.</a>  
            <br><br>
            The Children and Families Act 2014 states that young carers have a legal right to a young carers assessment. The purpose of the assessment is to identify individual needs and how the caring role affects the wellbeing of the young person. The assessment recognises the strengths of the family as well as identifying challenges faced by its members. The wishes, feelings and aspirations of the young person are at the centre of our Young Carers Needs Assessment. </p>
          <p>In order for us to carry out a Young Carer Needs Assessment we need your permission to share the following data with Suffolk County Council.			</p>
          <ul>
              <li>Young person’s name</li>
              <li>Young person’s address</li>
              <li>Young person’s date of birth</li>
              <li>Young person’s gender</li>
              <li>Name of the cared for person</li>
              <li>A summary of your completed assessment</li>
          </ul>
       <br>
            <mat-radio-group [ngStyle]="{'color': (this.carerForm.get('consentYcStatutoryAssessment').value === null && this.showErrors)  ? 'red' : ''}"  class="row-radio-group"  #consentYcStatutoryAssessment id="consentYcStatutoryAssessment" formControlName="consentYcStatutoryAssessment" > 
              <mat-radio-button class="row-radio-button" [value]="true"><span class="wrap-mat-radio-label">YES, I confirm I have the consent of this child's parent or guardian to request a statutory Young Carer Needs Assessment and that they are of aware this request and that the above information will be shared with Suffolk County Council</span></mat-radio-button>
              <mat-radio-button class="row-radio-button" [value]="false"><span class="wrap-mat-radio-label">NO, I do not wish to request a statutory Young Carer Needs Assessment</span></mat-radio-button>
            </mat-radio-group>
        </div>

    </div>
    <div id="consentProAdult" *ngIf="(this.whoReferring === 'another'  && (relationship === 2330 || relationship === 2331 || relationship === 2358) && (this.carerForm.get('age').value > 17 || (!this.carerForm.get('age').value && this.carerForm.get('age').value !== 0)))" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
      <!-- done! -->
      <div fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
        <label><b>Consent for Suffolk Family Carers to hold personal details</b></label><br>
        <mat-checkbox [ngStyle]="{'color': (this.carerForm.get('consentStoreData').value !== true && this.showErrors)  ? 'red' : ''}" labelPosition="right"   formControlName="consentStoreData">Yes, I confirm I have the consent of this person to provide Suffolk Family Carers with their personal details, and for them to be held as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a></mat-checkbox>
        <br><br>
        <label><b>Consent for Suffolk Family Carers to share personal details</b></label><br>
        <mat-checkbox labelPosition="right"   formControlName="consentShareData">Yes, I confirm I have the consent of this person for Suffolk Family Carers to share their personal details as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a>, and that they are aware of how they may be used</mat-checkbox>
        <br><br>
  
        
        <label><b>Allowed Support Contact Methods for Support</b></label><br>
        I confirm I have the consent of this person for  Suffolk Family Carers to contact them  via the following methods to discuss Support
        <ul>
            <mat-checkbox labelPosition="right"   formControlName="consentContactTelephone">Telephone</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactEmail">E-mail</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactSms">SMS text message</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactPost">Post</mat-checkbox><br>
           </ul>
       
  
        <br>
      </div>
    </div>

    <div id="consentParent" *ngIf="(this.whoReferring === 'another'  && (relationship === 2328 || relationship === 2329))" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
     <!-- Done! -->
     <div fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
      <label><b>Consent for Suffolk Family Carers to hold personal details</b></label><br>
      <mat-checkbox  [ngStyle]="{'color': (this.carerForm.get('consentStoreData').value !== true && this.showErrors)  ? 'red' : ''}" labelPosition="right"   formControlName="consentStoreData">Yes, I consent to Suffolk Family Carers holding my child's personal details as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a></mat-checkbox>
      <br><br>
      <label><b>Consent for Suffolk Family Carers to share personal details</b></label><br>
      <mat-checkbox labelPosition="right"   formControlName="consentShareData">Yes, I consent to Suffolk Family Carers sharing my child's personal details as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a></mat-checkbox>
      <br><br>
      <label><b>Allowed Support Contact Methods for Support</b></label><br>
      I confirm I have the consent of this person for  Suffolk Family Carers to contact them  via the following methods to discuss Support
      <ul>
          <mat-checkbox labelPosition="right"   formControlName="consentContactTelephone">Telephone</mat-checkbox><br>
          <mat-checkbox labelPosition="right"   formControlName="consentContactEmail">E-mail</mat-checkbox><br>
          <mat-checkbox labelPosition="right"   formControlName="consentContactSms">SMS text message</mat-checkbox><br>
          <mat-checkbox labelPosition="right"   formControlName="consentContactPost">Post</mat-checkbox><br>
         </ul>
         <br>

  
    </div>
      <div *ngIf="(this.carerForm.get('age').value < 18 && this.carerForm.get('age').value > 4)" fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
        <label><b>Young Carer Needs Assessment – Data Sharing</b></label><br>
        <p>Suffolk Family Carers carry out statutory Young Carer Needs Assessments on behalf of Suffolk County Council. More information about Young Carer Needs Assessments can be found <a href="https://www.carersuk.org/help-and-advice/practical-support/getting-care-and-support/young-carers-and-carers-of-children-under-18" target="_blank">here.</a>  
          <br><br>
          The Children and Families Act 2014 states that young carers have a legal right to a young carers assessment. The purpose of the assessment is to identify individual needs and how the caring role affects the wellbeing of the young person. The assessment recognises the strengths of the family as well as identifying challenges faced by its members. The wishes, feelings and aspirations of the young person are at the centre of our Young Carers Needs Assessment. </p>
        <p>In order for us to carry out a Young Carer Needs Assessment we need your permission to share the following data with Suffolk County Council.			</p>
        <ul>
            <li>Young person’s name</li>
            <li>Young person’s address</li>
            <li>Young person’s date of birth</li>
            <li>Young person’s gender</li>
            <li>Name of the cared for person</li>
            <li>A summary of your completed assessment</li>
        </ul>
     <br>
          <mat-radio-group [ngStyle]="{'color': (this.carerForm.get('consentYcStatutoryAssessment').value === null && this.showErrors)  ? 'red' : ''}" class="row-radio-group"  #consentYcStatutoryAssessment id="consentYcStatutoryAssessment" formControlName="consentYcStatutoryAssessment" > 
            <mat-radio-button class="row-radio-button" [value]="true"><span class="wrap-mat-radio-label">YES, I would like to request a statutory Young Carer Needs Assessment for my child and am aware that the above information will be shared with Suffolk County Council</span></mat-radio-button>
            <mat-radio-button class="row-radio-button" [value]="false"><span class="wrap-mat-radio-label">NO, I do not wish to request a statutory Young Carer Needs Assessment for my child</span></mat-radio-button>
          </mat-radio-group> 
      </div>
  
    </div>
    <div id="consentSelfOwn" *ngIf="((this.whoReferring === 'myself' || this.whoReferring === 'nonCarer') && this.index === 0)" fxLayout="row wrap" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
      <!-- Done! -->
      <div fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
        <label><b>Consent for Suffolk Family Carers to hold personal details</b></label><br>
        <mat-checkbox [ngStyle]="{'color': (this.carerForm.get('consentStoreData').value !== true && this.showErrors)  ? 'red' : ''}" labelPosition="right"   formControlName="consentStoreData">Yes, I consent to Suffolk Family Carers holding my personal details as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a></mat-checkbox>
        <br><br>
        <label><b>Consent for Suffolk Family Carers to share personal details</b></label><br>
        <mat-checkbox labelPosition="right"   formControlName="consentShareData">Yes, I consent to Suffolk Family Carers sharing my personal details as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a></mat-checkbox>
        <br><br>

      
      
      
        <label><b>Allowed Support Contact Methods for Support</b></label><br>
        I provide consent for Suffolk Family Carers to contact me  via the following methods to discuss support
          <ul>
            <mat-checkbox labelPosition="right"   formControlName="consentContactTelephone">Telephone</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactEmail">E-mail</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactSms">SMS text message</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactPost">Post</mat-checkbox><br>
           </ul>
       

        <mat-checkbox *ngIf="(this.whoReferring !== 'nonCarer')"  labelPosition="right"   formControlName="subscribeNewsletter">I would like to subscribe to the Suffolk Family Carers newsletter, delivered via e-mail. I understand I can unsubscribe at any time by visiting www.suffolkfamilycarers.org</mat-checkbox>
        <br>
      </div>
        
    </div>
    <div id="consentSelfAdult"  *ngIf="((this.whoReferring === 'myself' || this.whoReferring === 'nonCarer') && this.index !== 0 && (this.carerForm.get('age').value > 17 || (!this.carerForm.get('age').value && this.carerForm.get('age').value !== 0)))" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
       <!-- Done! -->
      <div fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
      <label><b>Consent for Suffolk Family Carers to hold personal details</b></label><br>
      <mat-checkbox [ngStyle]="{'color': (this.carerForm.get('consentStoreData').value !== true && this.showErrors)  ? 'red' : ''}" labelPosition="right"   formControlName="consentStoreData">Yes, I confirm I have the consent of this person to provide Suffolk Family Carers with their personal details, and for them to be held as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a></mat-checkbox>
      <br><br>
      <label><b>Consent for Suffolk Family Carers to share personal details</b></label><br>
      <mat-checkbox labelPosition="right"   formControlName="consentShareData">Yes, I confirm I have the consent of this person for Suffolk Family Carers to share their personal details as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a>, and that they are aware of how they may be used</mat-checkbox>
      <br><br>

    
    
    
      <label><b>Allowed Support Contact Methods for Support</b></label><br>
      I confirm I have the consent of this person for Suffolk Family Carers to contact them  via the following methods to discuss Support
      <ul>
          <mat-checkbox labelPosition="right"   formControlName="consentContactTelephone">Telephone</mat-checkbox><br>
          <mat-checkbox labelPosition="right"   formControlName="consentContactEmail">E-mail</mat-checkbox><br>
          <mat-checkbox labelPosition="right"   formControlName="consentContactSms">SMS text message</mat-checkbox><br>
          <mat-checkbox labelPosition="right"   formControlName="consentContactPost">Post</mat-checkbox><br>
         </ul>
     

      <br>
    </div>
    </div>
    <div id="consentSelfYc"  *ngIf="((this.whoReferring === 'myself' || this.whoReferring === 'nonCarer' ) && this.index !== 0 && this.carerForm.get('age').value < 18)" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
        <!-- Done! -->
        <div fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
          <label><b>Consent for Suffolk Family Carers to hold personal details</b></label><br>
          <mat-checkbox [ngStyle]="{'color': (this.carerForm.get('consentStoreData').value !== true && this.showErrors)  ? 'red' : ''}" labelPosition="right"   formControlName="consentStoreData">Yes, I confirm I have the consent of this child's parent or guardian to provide Suffolk Family Carers with this child's personal details, and for them to be held as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a></mat-checkbox>
          <br><br>
          <label><b>Consent for Suffolk Family Carers to share personal details</b></label><br>
          <mat-checkbox labelPosition="right"   formControlName="consentShareData">Yes, I confirm I have the consent of this child's parent or guardian for Suffolk Family Carers to share this child's personal details as outlined in the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a>, and that the parent/guardian is aware of how they may be used</mat-checkbox>
          <br><br>
    
      
        </div>
        <label><b>Allowed Support Contact Methods for Support</b></label><br>
        I confirm I have the consent of this person for  Suffolk Family Carers to contact them  via the following methods to discuss Support
        <ul>
            <mat-checkbox labelPosition="right"   formControlName="consentContactTelephone">Telephone</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactEmail">E-mail</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactSms">SMS text message</mat-checkbox><br>
            <mat-checkbox labelPosition="right"   formControlName="consentContactPost">Post</mat-checkbox><br>
           </ul>
           <br>

        <div *ngIf="(this.carerForm.get('age').value < 18 && this.carerForm.get('age').value > 4)" fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
          <label><b>Young Carer Needs Assessment – Data Sharing</b></label><br>
          <p>Suffolk Family Carers carry out statutory Young Carer Needs Assessments on behalf of Suffolk County Council. More information about Young Carer Needs Assessments can be found <a href="https://www.carersuk.org/help-and-advice/practical-support/getting-care-and-support/young-carers-and-carers-of-children-under-18" target="_blank">here.</a>  
            <br><br>
            The Children and Families Act 2014 states that young carers have a legal right to a young carers assessment. The purpose of the assessment is to identify individual needs and how the caring role affects the wellbeing of the young person. The assessment recognises the strengths of the family as well as identifying challenges faced by its members. The wishes, feelings and aspirations of the young person are at the centre of our Young Carers Needs Assessment. </p>
          <p>In order for us to carry out a Young Carer Needs Assessment we need your permission to share the following data with Suffolk County Council.			</p>
          <ul>
              <li>Young person’s name</li>
              <li>Young person’s address</li>
              <li>Young person’s date of birth</li>
              <li>Young person’s gender</li>
              <li>Name of the cared for person</li>
              <li>A summary of your completed assessment</li>
          </ul>
       <br>
            <mat-radio-group [ngStyle]="{'color': (this.carerForm.get('consentYcStatutoryAssessment').value === null && this.showErrors)  ? 'red' : ''}" class="row-radio-group"  #consentYcStatutoryAssessment id="consentYcStatutoryAssessment" formControlName="consentYcStatutoryAssessment" > 
              <mat-radio-button class="row-radio-button" [value]="true"><span class="wrap-mat-radio-label">YES, I confirm I have the consent of this child's parent or guardian to request a statutory Young Carer Needs Assessment and that they are aware of this request and that the above information will be shared with Suffolk County Council</span></mat-radio-button>
              <mat-radio-button class="row-radio-button" [value]="false"><span class="wrap-mat-radio-label">NO, I do not wish to request a statutory Young Carer Needs Assessment</span></mat-radio-button>
            </mat-radio-group>  
        </div>
    </div>
    <br>

</form>
<br>
<mat-divider></mat-divider>
