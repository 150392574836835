import { FormControl, Validators, FormGroup, ValidationErrors } from '@angular/forms'
import { RxFormBuilder, RxwebValidators } from '@rxweb/reactive-form-validators'
import { CaredFor } from './caredFor.model'



export class CaredForForm {
  guid = new FormControl()
  title = new FormControl()
  firstName = new FormControl()
  lastName = new FormControl()
  dateOfBirth = new FormControl()
  ageRange = new FormControl()
  postCode = new FormControl()
  addressLine1 = new FormControl()
  addressLine2 = new FormControl()
  addressLine3 = new FormControl()
  city = new FormControl()
  county = new FormControl()
  cfConditions = new FormControl()
  doesThisPersonLive = new FormControl()
  travelToAddress = new FormControl()
  gender = new FormControl()
  age = new FormControl()


  constructor(
    caredFor: CaredFor
  ) {
    this.guid.setValue(caredFor.guid)
    this.firstName.setValue(caredFor.firstName)
    this.firstName.setValidators([Validators.required])

    this.lastName.setValue(caredFor.lastName)

    this.title.setValue(caredFor.title)

    this.dateOfBirth.setValue(caredFor.dateOfBirth)
    //this.dateOfBirth.setValidators([Validators.required])
    //this.travelToAddress.setValidators([Validators.pattern("^[0-9]*$")])


    //this.dateOfBirth.setValidators([Validators.required])

    // this.dateOfBirth.setValidators(([RxwebValidators.required({conditionalExpression:x => x.ageRange == null || x.ageRange == '' })]))

    // this.ageRange.setValidators(([RxwebValidators.required({conditionalExpression:x => !x.dateOfBirth  })]))

  }
}

