<form [formGroup]="yourDetailsForm">
  <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
    <mat-select   matInput #relationshipSelect id="relationshipSelect" formControlName="relationship" placeholder="Relationship to Carer"  (selectionChange)='onRelationshipSelected($event)'>
      <mat-option *ngFor="let item of relationships" [value]="item.value">
        {{item.viewValue}}
        
      </mat-option>
      <!-- <mat-option *ngIf="this.referralSource=='sfc'" value="sfc">Suffolk Family Carers</mat-option>
      <mat-option *ngIf="this.referralSource=='nd'" value="nd">ND Referral</mat-option> -->
    </mat-select>
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="firstName" placeholder="First Name">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    
    <input matInput formControlName="lastName" placeholder="Last Name">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="email" placeholder="E-mail address" oninput="this.value = this.value.toLowerCase()" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,50}$">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="telephone" placeholder="Telephone number">
  </mat-form-field>

  </div>
  <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <div fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%"> 

    <mat-form-field style="width: calc(100% - 150px)"  >
   
      <input matInput formControlName="postCode" placeholder="Postcode"> 
    </mat-form-field> 
    <button class="single-button light-blue-button" mat-raised-button type="button" (click)="addressLookup()">Lookup address</button>
  </div>
  

    
 
  <mat-form-field *ngIf="this.addresses?.length > 0"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-label [class.glow]="selectAddress.empty">Select address</mat-label>
    <mat-select   matInput #selectAddress id="selectAddress"  (selectionChange)='onAddressSelected(selectAddress.value)'> 
      <mat-option *ngFor="let item of addresses let i = index"  [value]="i">
        {{item.line_1}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="this.addressesError" class="glow"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
    {{ this.addressesError }}
    </div>
  </div>
  <div id="orgPanel" *ngIf="whoReferring === 'Professional' || whoReferring === 'Education Provider' || this.referralSource === 'sfc' || this.referralSource === 'nd'" [@slideInOut] fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
 
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="orgName" placeholder="Organisation name">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    
    <input matInput formControlName="orgRole" placeholder="Your role">
  </mat-form-field>
  </div>
  <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="addressLine1" placeholder="Address line 1">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="addressLine2" placeholder="Address line 2">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="addressLine3" placeholder="Address line 3">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="city" placeholder="Town/City">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="county" placeholder="County">
  </mat-form-field>

  </div>
  <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <mat-form-field  fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%">
    <textarea matInput formControlName="comments" placeholder="Brief description of what support these carers require" rows="4" maxlength="1000"></textarea>
    
  </mat-form-field>
  
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-select   matInput #howHeardSelect id="howHeardSelect" formControlName="howDidYouHear" placeholder="How did you hear about us?" (selectionChange)='onHowDidYouHearSelected($event)'  >
      <mat-option *ngFor="let item of howDidYouHearList" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="this.yourDetailsForm.get('howDidYouHear').value === 2449"  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
    <input matInput formControlName="howDidYouHearOther" placeholder="How did you hear about us (other)?" maxlength="250">
  </mat-form-field>
  </div>
  <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <div *ngIf="!this.hideContact" fxFlex="0 1 calc(50% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
    <br>
    <label>{{contactText}}</label><br>
    <mat-radio-group  #advisorContact id="advisorContact" formControlName="advisorContact" (change)="advisorContactRadioChange($event)" > 
      <mat-radio-button [value]="true">Yes</mat-radio-button>
      <mat-radio-button [value]="false" [checked]='true'>No</mat-radio-button>
    </mat-radio-group>
  </div>
  
  <mat-form-field *ngIf="this.yourDetailsForm.get('advisorContact').value === true"  fxFlex="0 1 calc(50% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-select   matInput #preferredContactMethod id="preferredContactMethod" formControlName="preferredContactMethod" placeholder="Preferred conatct method" >
      <mat-option *ngFor="let item of contactMethods" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  </div><br>

  <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <div fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
    <mat-checkbox [ngStyle]="{'color': (this.yourDetailsForm.get('consentToSfcHoldingInfo').invalid && this.showErrors)  ? 'red' : 'black'}"   labelPosition="right"  id="consentToSfcHoldingInfo" formControlName="consentToSfcHoldingInfo">Yes, I consent to Suffolk Family Carers holding my personal details in accordance with the <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a> (link opens in a new window).</mat-checkbox>

  </div>
  <div fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
    <mat-checkbox [ngStyle]="{'color': (this.yourDetailsForm.get('consentAllIndividuals').invalid && this.showErrors)  ? 'red' : 'black'}" labelPosition="right"  id="consentAllIndividuals" formControlName="consentAllIndividuals">Yes, I have the consent of all individuals recorded on this registration for Suffolk Family Carers to hold their personal information in accordance with the  <a href="https://suffolkfamilycarers.org/privacy-statement/" target="_blank">Privacy Statement</a> and that these individuals are  aware that this registration has been submitted.</mat-checkbox>

  </div>
  <div *ngIf="whoReferring === 'Professional' || whoReferring === 'Education Provider'" fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%" >
    <mat-checkbox labelPosition="right"   formControlName="subscribeProNewsletter">I would like to subscribe to {{whoReferring}}s Connected e-mail newsletter</mat-checkbox>

  </div>

</div>
<div *ngIf="(this.yourDetailsForm.status==='INVALID' && this.showErrors)">
<br>
   <label class="glow">Please complete all required fields (highlighted in red) in this section before continuing. </label>
   <br>
   
</div>
<br>
    </form>