import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { FormControl, Validators, FormBuilder, FormGroup, ReactiveFormsModule, NgForm, FormArray, ValidationErrors, AbstractControl } from '@angular/forms';
import { of, Subscription } from 'rxjs';
import { ReferralFormService } from './referral-form.service'
import { TOUCH_BUFFER_MS } from '@angular/cdk/a11y';
import { ChildActivationStart } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { ChangeDetectorRef } from '@angular/core';
import {AppSettings} from '../AppSetting';
import { ApiService } from '../api.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import {MatDialog} from '@angular/material/dialog';
import { DialogComponentComponent } from '../dialog-component/dialog-component.component';
// import { ConsoleReporter } from 'jasmine';


interface MfLookup {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-referral-form',
  templateUrl: './referral-form.component.html',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./referral-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,

  
})
export class ReferralFormComponent implements OnInit, OnDestroy {

  @ViewChild('stepper') stepper: MatStepper;

  smallScreen: boolean;
  whoReferring: string;
  yourDetailsLabel: string;
  startForm:FormGroup;
  completedRef: number;
  cardForFormValidControl = new FormControl();
  saveButtonText: string;
  referralSource: string = this.route.snapshot.queryParamMap.get('refSource');

  addCarerButtonText: string;

  referralForm: FormGroup;
  referralFormSub: Subscription;
  formInvalid: boolean = false;
  carers: FormArray;
  caredFors: FormArray;
  yourDetails: FormGroup;
  carerRelationships: FormArray;
  position: string; 
  relationshipList: MfLookup[] = AppSettings.CarerRelationshipList; 
  saving: boolean;
  submitError: string;

  whoReferringErrors: boolean;
  carerErrors: boolean;
  caredForErrors: boolean;
  consentErrors: boolean
  selectedIndex: number = 0;
  isDebug: string = this.route.snapshot.queryParamMap.get('debug');
  debug: boolean
  


  constructor(private route: ActivatedRoute, public dialog: MatDialog, private apiService: ApiService, private referralFormService: ReferralFormService, private chRef: ChangeDetectorRef, private router: Router) { }

   ngOnInit() {

    

        //Test iFrame setup
        // let box = parent.document.getElementById('header');
        // let height = box.offsetHeight;
        // console.log('Header height is: ' + height)
    
    //this.stepperSFC.selectionChange.subscribe((event) => { this.scrollToSectionHook(event.selectedIndex); });
    //this.stepperSFC.selectionChange.subscribe()
    this.saveButtonText = 'Submit Registration';
    this.whoReferringErrors = false;
    this.addCarerButtonText = "Add another Carer";
    this.carerErrors = false;
    this.caredForErrors = false;
    this.consentErrors = false;
    console.log("Referral source: " + this.route.snapshot.queryParamMap.get("refSource"));
    
    
     this.cardForFormValidControl.setErrors({'incorrect': true});



     this.yourDetailsLabel = "Carer details";
    this.referralFormSub = this.referralFormService.referralForm$
      .subscribe(referral => {
          this.referralForm = referral
          this.carers = this.referralForm.get('carers') as FormArray
          this.caredFors = this.referralForm.get('caredFors') as FormArray
          this.yourDetails = this.referralForm.get('yourDetails') as FormGroup
          this.carerRelationships = this.referralForm.get('carerRelationships') as FormArray
        });
        //this.referralFormService.addCarer();
        //this.referralFormService.addCaredFor();
        this.referralFormService.addCarerCaredFor();

        this.caredFors.statusChanges.subscribe(newStaus => {
          this.checkCaredForIsValid();

      })

      this.carerRelationships.statusChanges.subscribe(newStaus => {
        this.checkCaredForIsValid();
    })
        
  }

  ngAfterViewInit(){

    if (this.isDebug === "true")
    {
      console.log('Debug mode ON');
      this.debug = true;
    }

    if (this.referralSource != null)
    {
      if (this.referralSource === "nd")
      {
        //Do nothing yet
        this.referralForm.controls.referralUrl.setValue(this.referralSource.toLowerCase())

      }
      else if (this.referralSource === "ndpeer")
      {
        this.referralForm.controls.referredBy.setValue("myself")
        this.whoReferring = "nonCarer";
        this.nonCarerSelected();
        //Do nothing yet
        this.referralForm.controls.referralUrl.setValue(this.referralSource.toLowerCase())

      }
      else if (this.referralSource === "tp")
      {
        this.referralForm.controls.referredBy.setValue("another")
        this.whoReferring = "another";
        this.onOptionsSelected("another");
        this.referralForm.controls.referralUrl.setValue(this.referralSource.toLowerCase())
        console.log("Referred by value: " + this.whoReferring + "(TP)")

      }
      else
      {
        this.referralForm.controls.referredBy.setValue("another")
        this.whoReferring = "another";
        this.onOptionsSelected("another");
        this.referralForm.controls.referralUrl.setValue(this.referralSource.toLowerCase())
        //this.referralForm.controls['referredBy'].setErrors(null);
        console.log("Referred by value: " + this.whoReferring)
        
      }
    }
    



    this.stepper.animationDone.subscribe((event) => { this.scrollToSectionHook(); });
    // if (this.referralSource == "sfc")
    // {

    //   this.referralForm.controls.referredBy.setValue(this.referralSource)
    //   this.referralForm.controls.referralUrl.setValue('sfc')

    //   this.stepper.selectedIndex = 1
    // }
    // else if (this.referralSource == "nd")
    // {
    //   this.referralForm.controls.referralUrl.setValue('nd')
    //   this.stepper.selectedIndex = 1


    // }
  }

  scrollToSectionHook()
  { 
    var yourHeight = 600;

    const stepId = this.stepper._getStepLabelId(this.stepper.selectedIndex);
    //const stepElement = document.getElementById(stepId);
    const stepElement = document.getElementById("pre-header");

    console.log("Step ID: " + stepId)
    //document.documentElement.scrollTop = document.getElementById("topStep").offsetTop;
    if (stepElement && stepId !== "cdk-step-label-0-0") {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      if (stepElement && stepId) {
      stepElement.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      var scrolledY = window.scrollY;

      if(scrolledY){
      window.scroll(0, scrolledY - yourHeight);
    }
      

    }
  }

    

  }
  
  move(index: number, section: string) {
    if (this.whoReferring!=="myself")
    {
      index++;
    }
    this.position = section;
    this.stepper.selectedIndex = index;
    
  }

  setIndex(event) {
    // this.selectedIndex = event.selectedIndex;
    // console.log(this.selectedIndex)
    // if (this.selectedIndex === 3 && this.whoReferring === "myself")
    // {
    //   const carerForm = this.referralForm.get('carers') as FormArray
    //   console.log('Myself and consent page')
    //   for (let  carer  of carerForm.controls) {
        
    //     carer['controls'].consentStoreData.setValidators([Validators.requiredTrue]);
    //     carer['controls'].consentStoreData.updateValueAndValidity
    //   }
      
    // }
    // else if (this.selectedIndex === 4 && this.whoReferring === "another")
    // {
    //   console.log('Another and consent page')

    //   const carerForm = this.referralForm.get('carers') as FormArray

    //   for (let  carer  of carerForm.controls) {
        
    //     carer['controls'].consentStoreData.setValidators([Validators.requiredTrue]);
    //     carer['controls'].consentStoreData.updateValueAndValidity

    //   }
    // }
    // else
    // {
    //   const carerForm = this.referralForm.get('carers') as FormArray
    //   console.log('Not consent page')

    //   for (let  carer  of carerForm.controls) {
        
    //     carer['controls'].consentStoreData.setValidators(null);
    //     carer['controls'].consentStoreData.setErrors(null);
    //     carer['controls'].consentStoreData.updateValueAndValidity


    //   }
    // }
  }
  


    ngOnDestroy() {
      this.referralFormSub.unsubscribe()
    }
    addCarer() {
      this.referralFormService.addSingleCarer()

    }
  
    deleteCarer(index: number) {
      this.referralFormService.deleteCarer(index)

    }
    addCaredFor() {
      this.referralFormService.addSingleCaredFor()
      this.cardForFormValidControl.setErrors({'incorrect': true});
    }
  
    deleteCaredFor(index: number) {
      this.referralFormService.deleteCaredFor(index)

    }

    
    
    testDialog(){
      this.dialog.open(DialogComponentComponent);
    }
  
    saveReferral() {
      
      console.log('referral saving')

      if (this.checkConsentValid())
      {
        //Valid
        console.log('Form valid')

        console.log(this.referralForm.getRawValue())

       //this.saving = true;
      this.saveButtonText = "Submitting - Please wait";
        this.apiService.submitForm(this.referralForm.getRawValue())
        .subscribe((data) => {
          this.completedRef = data
          
          
        },
        error => { console.log(error);
          this.saveButtonText = 'Submit Registration';
          //this.submitError = "Sorry, there was a problem submitting your details. Please try again."
          this.saving = false;
          //this.chRef.detectChanges();
          this.dialog.open(DialogComponentComponent);
         
        });

      }
      else{
        //Invalid
        console.log('Invalid')
        this.consentErrors = true;

        this.saving = false;

      }

      
      // var cleanForm = this.referralForm.get('carers') as FormArray

      //   for (let  carer  of cleanForm.controls) {
      //     console.log('Value is: ' + carer.get('dateOfBirth').value)
      //     var momentObj = moment(carer.get('dateOfBirth').value);
      //     var momentString = momentObj.format('DD/MM/YYYY');
      //     carer.get('dateOfBirth').setValue(momentString);
      //     console.log(momentString);
      //     console.log('Value after is: ' + carer.get('dateOfBirth').value)

      //     //this.addCarerRelationship(carer.get('guid').value,newCaredForGuid)
      //   }


      this.scrollToTop();

      
    }

    checkCaredForIsValid()
    {
      
      if (this.formArray.length === 1)
      {
        
        if(this.caredFors.valid && this.carerRelationships.valid)
        {
          this.cardForFormValidControl.setErrors(null);
        }
        else
        {
          this.cardForFormValidControl.setErrors({'incorrect': true});
        }
      }
      else if (this.caredFors.valid)
      { 
     
        this.cardForFormValidControl.setErrors(null);
      }
      else{
        this.cardForFormValidControl.setErrors({'incorrect': true});
      }
    }

    onOptionsSelected(value:string)
    {
      for (let  carer  of this.carers.controls) {
        carer.get('consentStoreData').setValue(false);
        carer.get('consentShareData').setValue(false);
        carer.get('consentYcStatutoryAssessment').setValue(false);
       }

      if (value!=="myself")
      {
        this.yourDetailsLabel = "Carer details"
        this.referralForm['controls'].yourDetails['controls'].relationship.setValidators([Validators.required]);
        this.referralForm['controls'].yourDetails['controls'].consentToSfcHoldingInfo.setValidators([Validators.requiredTrue]);
        this.referralForm['controls'].yourDetails['controls'].consentAllIndividuals.setValidators([Validators.requiredTrue]);
        this.referralForm['controls'].yourDetails['controls'].firstName.setValidators([Validators.required]);
        this.referralForm['controls'].yourDetails['controls'].lastName.setValidators([Validators.required]);
        this.referralForm['controls'].yourDetails['controls'].email.setValidators([Validators.required]);


      }
      else if (value==="myself")
      {
        this.yourDetailsLabel = "Your details"
        this.referralForm['controls'].yourDetails['controls'].relationship.setValidators(null);
        this.referralForm['controls'].yourDetails['controls'].consentToSfcHoldingInfo.setValidators(null);
        this.referralForm['controls'].yourDetails['controls'].consentAllIndividuals.setValidators(null);
        this.referralForm['controls'].yourDetails['controls'].firstName.setValidators(null);
        this.referralForm['controls'].yourDetails['controls'].lastName.setValidators(null);
        this.referralForm['controls'].yourDetails['controls'].email.setValidators(null);

        this.referralForm['controls'].yourDetails['controls'].relationship.setErrors(null);
        this.referralForm['controls'].yourDetails['controls'].consentToSfcHoldingInfo.setErrors(null);
        this.referralForm['controls'].yourDetails['controls'].consentAllIndividuals.setErrors(null);
        this.referralForm['controls'].yourDetails['controls'].firstName.setErrors(null);
        this.referralForm['controls'].yourDetails['controls'].lastName.setErrors(null);
        this.referralForm['controls'].yourDetails['controls'].email.setErrors(null);

        this.referralForm['controls'].yourDetails.reset();
       }
       else if (value==="nonCarer")
      {
        this.nonCarerSelected();
       }
       
    }

    public nonCarerSelected()
    {
      this.yourDetailsLabel = "Your details"
      this.addCarerButtonText = "Add another person"
      this.referralForm['controls'].yourDetails['controls'].relationship.setValidators(null);
      this.referralForm['controls'].yourDetails['controls'].consentToSfcHoldingInfo.setValidators(null);
      this.referralForm['controls'].yourDetails['controls'].consentAllIndividuals.setValidators(null);
      this.referralForm['controls'].yourDetails['controls'].firstName.setValidators(null);
      this.referralForm['controls'].yourDetails['controls'].lastName.setValidators(null);
      this.referralForm['controls'].yourDetails['controls'].email.setValidators(null);

      this.referralForm['controls'].yourDetails['controls'].relationship.setErrors(null);
      this.referralForm['controls'].yourDetails['controls'].consentToSfcHoldingInfo.setErrors(null);
      this.referralForm['controls'].yourDetails['controls'].consentAllIndividuals.setErrors(null);
      this.referralForm['controls'].yourDetails['controls'].firstName.setErrors(null);
      this.referralForm['controls'].yourDetails['controls'].lastName.setErrors(null);
      this.referralForm['controls'].yourDetails['controls'].email.setErrors(null);

      this.referralForm['controls'].yourDetails.reset();

      this.referralFormService.deleteCaredFor(0)
    }

    public findInvalidControls() {
    const invalid = [];
    const controls = this.yourDetails.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
            console.log(name)
        }
    }
    return invalid;
  }

  public findInvalidControlsWhoReferring()
  {
    this.whoReferringErrors = true;
  }
  public findInvalidControlsCarer()
  {
    this.carerErrors = true;

    
  }
  
  

  public findInvalidControlsCaredFor()
  {
    this.caredForErrors = true;
  }

  

  // public setFocus()
  // {
  //   if(this.position)
  //   {
  //     window.location.hash = '';
  //     window.location.hash = this.position;
  //   }
    
   
    
  // }
  public stepChange(event: any): void {
    var index: number = event.selectedIndex;

    
  }

  scrollToTop() {
    (function smoothscroll() {
        var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(smoothscroll);
            window.scrollTo(0, currentScroll - (currentScroll / 8));
        }
    })();
}

  
  get formArray() {
    // Typecast, because: reasons
    // https://github.com/angular/angular-cli/issues/6099
    return <FormArray>this.referralForm.get('carerRelationships');
  }

  getRelationship(cGuid: string, cfGuid: string)
  {
    for (let x of this.carerRelationships.controls)
      {
      var thisItem = x as FormGroup;
      
      if (thisItem.controls.carerGuid.value === cGuid && thisItem.controls.cfGuid.value === cfGuid)
      { 
        for (let r of this.relationshipList)
        {
          if ( r.value === thisItem.controls.relationship.value)
          {
            var rel: string = r.viewValue
          }
        }
        
      
        
          return(rel)
        
        
      }

      }
    return("Relationship not entered");
  }

  public checkConsentValid(): boolean
  {
    var isvalid: boolean
    isvalid = true;
    for (let  carer  of this.carers.controls) {
      if (carer.get('consentStoreData').value !== true)
      {
        isvalid = false;
        console.log('Carer section invalid');
      }
      if(carer.get('age').value < 16  && this.whoReferring !== "nonCarer")
      {
        if (carer.get('consentYcStatutoryAssessment').value === null)
        {
          isvalid = false;
          console.log('Failed YC stat consent check')
        }
      }
    
    }
    return(isvalid)
  }

  public getFormValidationErrors()
  {
    console.log('Carer details: ' + this.carers.valid)
    console.log('Cared For details: ' + this.caredFors.valid)

    console.log('Relationships ' + this.carerRelationships.valid)
    console.log('Referral form ' + this.referralForm.valid)
    console.log('Referral source: ' + this.whoReferring)

  }




  

}
