import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { debounce, debounceTime, map, tap, catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { GP, IGPResponse, School, ISchoolResponse, IEVentDetails} from './importdata.class'
import { Referral } from './referral-form/_models/referral.model'
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { environment } from '../environments/environment';



@Injectable()


export class ApiService {
  

  myAppUrl: string = environment.scilApiUrl;
  myApiUrl: string;
  getEventDetailsUrl: string;
  ypsAssessmentUrl: string;
  eventConsentUrl: string;
  eventDeclineUrl: string;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    })
  };
  //getaddress.io
  API_KEY = 'S28K7xSlKk-TZjHkUdSsRA26027';
 

  constructor(private httpClient: HttpClient) {
    //Production URL: 
    this.myAppUrl = "https://api.suffolkfamilycarers.org/";

   //Testing URL
   //this.myAppUrl = "https://api.suffolkfamilycarers.org/testrjw/";
       
    this.myApiUrl = "api/referral";
    this.ypsAssessmentUrl = "api/yps/assessment"
    this.getEventDetailsUrl = "https://api.suffolkfamilycarers.org/api/events/details?inviteGuid="
    this.eventConsentUrl = "https://api.suffolkfamilycarers.org/api/events/consent"
    this.eventDeclineUrl = "https://api.suffolkfamilycarers.org/api/events/response"

    console.log(this.myAppUrl);
   }

  
  public getAddress(postCode: string){
    
    return this.httpClient.get(`https://api.getAddress.io/find/${postCode}?api-key=${this.API_KEY}&expand=true`)
    .pipe(
      retry(0),
      catchError(this.errorHandler)
    );
  }

  //NHS GP lookup
  NHS_API_KEY = ''

  public getGPAddress(postCode: string){
    const headers = new HttpHeaders({'subscription-key':'c5a426e18b574e6db3ba4916f561d486'});

    return this.httpClient.get(`https://api.nhs.uk/catchment/?location=${postCode}&offset=0&limit=5`,{headers})
    .pipe(
      retry(1),
      catchError(this.errorHandler))
    
    ;
  }

  

  public searchGP(filter: {name: string} = {name: ''}, page = 1): Observable<IGPResponse>{
    var listOfGPs = this.myAppUrl + `api/gp/search/?name=${filter.name}`
    return this.httpClient.get<IGPResponse>(listOfGPs)
    .pipe(
      tap((response: IGPResponse) => {
      response.results = response.results
      .map(gp => new GP(gp.orgCode, gp.name, gp.addressLine1, gp.addressLine2, gp.addressLine3, gp.addressLine4, gp.postCode, gp.manualEntry))
      console.log('Service response: ' + response)
      // return response
      }),
      catchError(this.errorHandler)
    );

  }

  public searchSchool(filter: {name: string} = {name: ''}, page = 1): Observable<ISchoolResponse>{
    var returnList = this.myAppUrl + `api/school/search/?name=${filter.name}`
    return this.httpClient.get<ISchoolResponse>(returnList)
    .pipe(
      tap((response: ISchoolResponse) => {
      response.results = response.results
      .map(r => new School(r.urn, r.establishmenName, r.addressLine1, r.addressLine2, r.addressLine3, r.town, r.county, r.postCode, r.manualEntry))
      console.log('Service response: ' + response)
      // return response
      }),
      catchError(this.errorHandler)
    );

  }

  public submitForm(referralForm): Observable<number> {
    
    return this.httpClient.post<number>(this.myAppUrl + this.myApiUrl, JSON.stringify(referralForm), this.httpOptions)
    .pipe(
      retry(0),
      catchError(this.errorHandler)
    );
    
  }

  public submitYpsAssessment(ypsAssessmentForm): Observable<number> {
    
    return this.httpClient.post<number>(this.myAppUrl + this.ypsAssessmentUrl, JSON.stringify(ypsAssessmentForm), this.httpOptions)
    .pipe(
      retry(0),
      catchError(this.errorHandler)
    );
    
  }

  public submitEventConsent(eventConsentForm): Observable<number> {

    return this.httpClient.post<number>(this.eventConsentUrl, JSON.stringify(eventConsentForm), this.httpOptions)
    .pipe(
      retry(0),
      catchError(this.errorHandler)
    );

  }

  public submitEventDecline(eventDeclineForm): Observable<number> {
    return this.httpClient.post<number>(this.eventDeclineUrl, JSON.stringify(eventDeclineForm), this.httpOptions)
    .pipe(
      retry(0),
      catchError(this.errorHandler)
    );
  }


  public getEventDetails(inviteGuid: string): Observable<IEVentDetails> {
    //console.log(this.myAppUrl+this.eventUrl+`/details?inviteGuid=${inviteGuid}`)
    return this.httpClient.get<IEVentDetails>(this.getEventDetailsUrl+inviteGuid)
    .pipe(
      retry(0),
      catchError(this.errorHandler)
    );
  }
  

  

  errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log('Error! ' + errorMessage);
    return throwError(errorMessage);
  }

  

  
  
}