import { Carer } from '../../form-carer-details/_models/carer.model'
import { CaredFor } from '../../form-cared-for/_models/caredFor.model'
import { YourDetails } from '../../your-details-form/_model/yourDetails.model'
import { CarerRelationship } from '../../carer-relationship/_models/carerRelationship.model'

export class Referral {
    referredBy: string
    name: string
    referralUrl: string
    carers: Carer[]
    caredFors: CaredFor[]
    yourDetails: YourDetails
    carerRelationships: CarerRelationship[]
    // consentAdvisorContact: boolean
    // consentYcAgree: boolean
    // consentYcShare: boolean
    // consentYcStatutory: boolean
    

    constructor(name: string, carers?: Carer[], caredFors?, referredBy?: string, yourDetails?: YourDetails) {
        this.referredBy = referredBy
        this.name = name
        this.carers = carers
        this.caredFors = caredFors
        this.yourDetails = this.yourDetails
    }
} 