import { FormControl, Validators, FormGroup } from '@angular/forms'
import { Carer } from './carer.model'
import { formatNumber } from '@angular/common'
import { RxwebValidators } from '@rxweb/reactive-form-validators'
import { validateBasis } from '@angular/flex-layout'


export class CarerForm {
  guid = new FormControl()
  firstName = new FormControl()
  lastName = new FormControl()
  title = new FormControl()
  dateOfBirth = new FormControl()
  age = new FormControl()
  gender = new FormControl()
  email = new FormControl()
  telephone = new FormControl()
  ethnicity = new FormControl()
  postCode = new FormControl()
  addressLine1 = new FormControl()
  addressLine2 = new FormControl()
  addressLine3 = new FormControl()
  city = new FormControl()
  county = new FormControl()
  caringRoleDetails = new FormControl()
  parentFirstName = new FormControl()
  parentLastName = new FormControl()
  parentEmail = new FormControl()
  parentTelephone = new FormControl()
  parentPostCode = new FormControl()
  parentAddressLine1 = new FormControl()
  parentAddressLine2 = new FormControl()
  parentAddressLine3 = new FormControl()
  parentCity = new FormControl()
  parentCounty = new FormControl()
  reasonForReferral = new FormControl()
  eContact1Email = new FormControl()
  eContact2Email = new FormControl()
  eContact1Telephone = new FormControl()
  eContact2Telephone = new FormControl()
  eContact1Relationship = new FormControl()
  eContact2Relationship = new FormControl()
  educationStatus = new FormControl()
  mainCarer = new FormControl()
  hoursCaring = new FormControl()
  nhsNumber = new FormControl();
  howDidYouHear = new FormControl();
  howDidYouHearOther = new FormControl();

  armedForcesShow = new FormControl()
  
  armedForcesMember = new FormControl()
  armedForcesBranch = new FormControl()
  armedForcesStatus = new FormControl()
  
  consentStoreData = new FormControl()
  consentShareData = new FormControl()
  consentYcStatutoryAssessment = new FormControl()
  consentContactTelephone = new FormControl()
  consentContactEmail = new FormControl()
  consentContactSms = new FormControl()
  consentContactPost = new FormControl()
  subscribeNewsletter = new FormControl()
  hasTabletOrComputer = new FormControl()
  requestContact = new FormControl()
  gpCode = new FormGroup({
    orgCode: new FormControl(),
    name: new FormControl(),
    addressLine1: new FormControl(),
    addressLine2: new FormControl(),
    addressLine3: new FormControl(),
    addressLine4: new FormControl(),
    postCode: new FormControl(),
    manualEntry: new FormControl()
  })

  schoolCode = new FormGroup({
    urn: new FormControl(),
    establishmenName: new FormControl(),
    addressLine1: new FormControl(),
    addressLine2: new FormControl(),
    addressLine3: new FormControl(),
    town: new FormControl(),
    county: new FormControl(),
    postCode: new FormControl(),
    manualEntry: new FormControl()

  })

  constructor(
    carer: Carer
  ) {
    this.guid.setValue(carer.guid)
    
    this.firstName.setValue(carer.firstName)

    this.lastName.setValue(carer.lastName)

    this.title.setValue(carer.title)

    this.dateOfBirth.setValue(carer.dateOfBirth)

    this.firstName.setValidators([Validators.required])
    this.lastName.setValidators([Validators.required])
    this.dateOfBirth.setValidators([Validators.required])
    this.gender.setValidators([Validators.required])
    this.ethnicity.setValidators([Validators.required])
    this.addressLine1.setValidators([Validators.required])
    this.city.setValidators([Validators.required])
    this.postCode.setValidators([Validators.required])
   // this.requestContact.setValidators([Validators.required])
    this.armedForcesShow.setValidators([Validators.required])
    this.age.setValidators([Validators.required])
    //this.telephone.setValidators([Validators.required])
    //this.email.setValidators([Validators.required])
    this.caringRoleDetails.setValidators([Validators.required])
    //this.consentStoreData.setValidators([Validators.required])    
  }
}