import { Component, OnInit, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core'
import { trigger, transition, animate, style } from '@angular/animations'
import { FormGroup, Validators } from '@angular/forms'
import {AppSettings} from '../AppSetting';
import { MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { ApiService } from '../api.service';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { ThrowStmt } from '@angular/compiler';
import { MatRadioChange } from '@angular/material/radio';
//import { ConsoleReporter } from 'jasmine';

interface MfLookup {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-your-details-form',
  templateUrl: './your-details-form.component.html',
  styleUrls: ['./your-details-form.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('400ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]
})
export class YourDetailsFormComponent implements OnInit {
  @Input() yourDetailsForm: FormGroup
  @Input() whoReferringParent: string
  @Input() showErrors: boolean;
  @Input() referralSource: string;
  addresses;
  addressesError: string;
  relationships: MfLookup[] = AppSettings.Relationships;
  howDidYouHearList: MfLookup[] = AppSettings.HowDidYouHear
  contactMethods: MfLookup[] = AppSettings.ContactMethods
  //Page variables
  whoReferring: string;
  contactText: string;
  hideContact: boolean;

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.whoReferring = "Unknown";
    this.contactText = "Would you like an Advisor to contact you regarding this registration?"
    this.hideContact = true;
    

  }

  ngAfterViewInit(){
    if (this.referralSource === "sfc")
    {
      this.whoReferring = 'Professional';
      //this.referralForm.controls.referredBy.setValue(this.referralSource)
      this.yourDetailsForm.controls.relationship.setValue(2330)
      this.yourDetailsForm.controls.orgName.setValue('Suffolk Family Carers')
    }
    else if (this.referralSource === "nd")
    {
      // this.whoReferring = 'Professional';
      // this.yourDetailsForm.controls.relationship.setValue(2330)
      // this.yourDetailsForm.controls.orgName.setValue('Barnardo\'s')


    }
    else if (this.referralSource === "tp")
    { this.whoReferring = 'Professional';
      this.yourDetailsForm.controls.relationship.setValue(2330)
      this.yourDetailsForm.controls.orgName.setValue("Turning Point")
      this.yourDetailsForm.controls.orgRole.setValue("Practitioner")
      this.yourDetailsForm.controls.howDidYouHear.setValue(2443);


    }
    else if (this.referralSource !== null)
    { this.whoReferring = 'Professional';
      this.yourDetailsForm.controls.relationship.setValue(2330)
      this.yourDetailsForm.controls.orgName.setValue(this.referralSource)
    }
    else
    {
      console.log("Referral source is null")
    }
  }

  addressLookup(){

    this.addressesError = null;
    this.addresses = null;

    var re = / ,/g
    var dirtyAddress;
    var postcode: string;
    postcode = this.yourDetailsForm.get('postCode').value;
    console.log('Address lookup starting with ' + postcode)
    this.apiService.getAddress(postcode).subscribe((data)=>{
    //console.log(data);
    this.addresses = data['addresses'];
    
      
      //for (var x = 0; x < dirtyAddress.length; x++)
      //{
       //   dirtyAddress[x].formatted_address = dirtyAddress[x].formatted_address.replace(re,'')
   
      //}
      //this.addresses = dirtyAddress;
     
    },
    error => { console.log(error);
     
      this.addressesError = "Sorry, that postcode wasn't found. Please check or enter the address manually."

  

     
    })
    

 
  
  }

  onAddressSelected(value:number)
  {
    var address = this.addresses;
    //console.log(address.length)
    this.yourDetailsForm.controls.addressLine1.setValue(address[value].line_1)
    this.yourDetailsForm.controls.addressLine2.setValue(address[value].line_2)
    this.yourDetailsForm.controls.addressLine3.setValue(address[value].line_3)
    this.yourDetailsForm.controls.city.setValue(address[value].town_or_city)
    this.yourDetailsForm.controls.county.setValue(address[value].county)

    
  }

  onHowDidYouHearSelected(event: MatSelectChange) {
    
    if (event.value !== "2449")
    {
      this.yourDetailsForm.controls.howDidYouHearOther.setValue(null);
    }
  }

  onRelationshipSelected(event: MatSelectChange) {
    
      this.whoReferring = (event.source.selected as MatOption).viewValue
      this.yourDetailsForm.controls.subscribeProNewsletter.setValue(null);
      if(this.whoReferring === "Professional" || this.whoReferring === "Other" || this.whoReferring==="Education Provider" )
      {

        this.contactText = "Would you like an Advisor to contact you regarding this registration?"
      }
      else
      { 
        this.contactText = "Would you like an Advisor to contact you regarding your child's caring role?"
        this.yourDetailsForm.controls.orgName.setValue(null);
        this.yourDetailsForm.controls.orgRole.setValue(null);
      }

    }

    advisorContactRadioChange($event: MatRadioChange) {
  
  
    if ($event.value !== "True") {
        this.yourDetailsForm.controls.preferredContactMethod.setValue(null);
        
      }
      
  
  }

}
