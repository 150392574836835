import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-complete',
  templateUrl: './form-complete.component.html',
  styleUrls: ['./form-complete.component.scss']
})
export class FormCompleteComponent implements OnInit {
  @Input() id: number
  @Input() refUrl: string


  constructor() {
    
    
  }
  ngOnInit(): void {
    
    console.log('Init form complete: ' + this.id)
  }

  saveReferral(){
    window.location.reload();
  }

}
