export class CarerRelationship {
    carerGuid: string
    cfGuid: string
    relationship: number
    


    constructor(carerGuid?: string, cfGuid?: string) {
        this.carerGuid = carerGuid;
        this.cfGuid = cfGuid;
    }
}



