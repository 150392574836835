<form [formGroup]="carerForm">
  <div  *ngIf="(index===0 && this.whoReferring === 'another')">
    <h3>Carer's details</h3>
  </div>
  <div  *ngIf="(index===0 && this.whoReferring === 'myself') || (index===0 && this.whoReferring === 'nonCarer')">
    <h3>Your details</h3>
  </div>
  <div  *ngIf="index>0">
    <mat-divider></mat-divider>
    <h3>{{index+1 | ordinal}} {{addButtonText}}'s details</h3> <mat-checkbox #sameAddressCheck  value="this.sameCarerAddressCheck"  (change)="sameCarerAddressChecked($event)">Address and GP are the same as the first {{addButtonText}}</mat-checkbox>

  </div>
  <div   fxLayout="row wrap"   fxLayout.lt-sm="column"   fxLayoutGap="32px"   fxLayoutAlign="flex-start">

 
  <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-label>Title</mat-label>
    <mat-select  #carerTitleSelect matInput id="carerTitleSelect" formControlName="title">
      <mat-option *ngFor="let item of titles" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field >
  
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="firstName" placeholder="First Name">
  </mat-form-field>
  
  <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="lastName" placeholder="Last Name">
  </mat-form-field>
  
    <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <mat-label [ngStyle]="{'color': (this.carerForm.get('dateOfBirth').invalid && this.showErrors)  ? 'red' : ''}" >Date of birth</mat-label>
      <input  matInput [formControl]="dobControl"  placeholder="DD/MM/YYYY" [matDatepicker]="picker" (ngModelChange)="term$.next($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  


  
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-label>Gender</mat-label>
    <mat-select  #carerGenderSelect matInput id="carerGenderSelect" formControlName="gender">
      <mat-option *ngFor="let item of genders" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-select   matInput #ethnicitySelect id="ethnicitySelect" formControlName="ethnicity" placeholder="Ethnicity">
      <mat-option *ngFor="let item of ethnicities" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div id="under16Warning" *ngIf="(this.whoReferring === 'myself' && this.index === 0 && this.age <13 && this.age >=0)" [@slideInOut] fxLayout="row wrap" 
    fxLayout.lt-sm="column" 
    fxLayoutGap="32px" 
    fxLayoutAlign="flex-start"
    class="glow" >
    Sorry, You need to be 13 or older to register. Please ask a parent or guardian to register for you.
    </div>
    <div id="negativeAgeWarning" *ngIf="(this.age < 0)" [@slideInOut] fxLayout="row wrap" 
    fxLayout.lt-sm="column" 
    fxLayoutGap="32px" 
    fxLayoutAlign="flex-start"
    class="glow" >
    Sorry, the Date of Birth cannot be in the future, or the Carer is too young. Did you mean {{this.suggestDate}}?
    </div>

</div>
  <div id="carerContactPanel" *ngIf="age >12" [@slideInOut] fxLayout="row wrap" 
    fxLayout.lt-sm="column" 
    fxLayoutGap="32px" 
    fxLayoutAlign="flex-start">
    <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <input matInput formControlName="email" placeholder="E-mail address" oninput="this.value = this.value.toLowerCase()" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$">
    </mat-form-field>

    <div fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <mat-checkbox #noEmailCheckbox  value="this.noEmail"  (change)="noEmailChecked($event)">Tick here if the e-mail address is not known, or the carer does not have one.</mat-checkbox>

    </div>

    <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <input matInput formControlName="telephone" placeholder="Telephone number">
    </mat-form-field>
  </div>

  

<div *ngIf="!this.sameCarerAddressCheck"
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  
  <div fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-form-field style="width: calc(100% - 150px)"  >
   
      <input matInput #postCodeTextBox formControlName="postCode" placeholder="Postcode" > 
    </mat-form-field> 
    
      <button class="single-button light-blue-button" mat-raised-button type="button" (click)="addressLookup('carer', this.carerForm.get('postCode').value)">Lookup address</button>

    </div>
  
  <mat-form-field *ngIf="this.carerAddresses?.length > 0"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
    <mat-label [class.glow]="selectAddress.empty">Select address</mat-label>
    <mat-select   matInput #selectAddress id="selectAddress"   (selectionChange)='onAddressSelected(selectAddress.value)'  > 
      <mat-option *ngFor="let item of carerAddresses let i = index"  [value]="i">
        {{item.line_1}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="this.carerAddressError" class="glow"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
  {{ this.carerAddressError }}
  </div>
  
  </div>
  <div  *ngIf="!this.sameCarerAddressCheck"  
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="addressLine1" placeholder="Address line 1">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="addressLine2" placeholder="Address line 2">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="addressLine3" placeholder="Address line 3">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="city" placeholder="Town/City">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
    <input matInput formControlName="county" placeholder="County">
  </mat-form-field>

  </div>
  <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <mat-form-field  *ngIf="this.whoReferring === 'nonCarer'" fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%">
    <textarea matInput formControlName="caringRoleDetails" placeholder="What would you like support with?" rows="4" maxlength="4000"></textarea>
    
  </mat-form-field>
  <mat-form-field *ngIf="this.whoReferring !== 'nonCarer'" fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%">
    <textarea matInput formControlName="caringRoleDetails" placeholder="Reason for registration and details of caring role" rows="4" maxlength="4000"></textarea>
    
  </mat-form-field>
  <mat-form-field *ngIf="this.whoReferring !== 'nonCarer'"  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-label>Hours caring</mat-label>
    <mat-select  #hoursCaringSelect matInput id="hoursCaringSelect" formControlName="hoursCaring">
      <mat-option *ngFor="let item of hoursCaring" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="(age < 18)" fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" [@slideInOut]>
    <mat-label>Reason For registration</mat-label>
    <mat-select  #reasonForReferralSelect matInput id="reasonForReferralSelect" formControlName="reasonForReferral">
      <mat-option *ngFor="let item of referralReasons" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="(age < 25)" fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" [@slideInOut]>
    <mat-label>Education Status</mat-label>
    <mat-select  #educationStatusSelect matInput id="educationStatusSelect" formControlName="educationStatus" (selectionChange)='oneducationStatusSelected($event)'>
      <mat-option *ngFor="let item of educationStatusList" [value]="item.value">
        {{item.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  </div>
  <div  id="schoolPanel" *ngIf="(
  this.carerForm.get('educationStatus').value === 85 ||
  this.carerForm.get('educationStatus').value === 792 )" [@slideInOut]>
<mat-card>
<mat-card-header>
<mat-card-title><label [ngStyle]="{'color': (this.carerForm.get('schoolCode.establishmenName').invalid && this.showErrors)  ? 'red' : 'black'}" class="card-title">School/college details</label></mat-card-title>
</mat-card-header>
<mat-card-content>
<div  *ngIf="!this.schoolManual" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="15px" fxLayoutAlign="flex-start">
<mat-form-field  fxFlex="0 1 calc(100% - 345px)" fxFlex.lt-md="100%" fxFlex.lt-sm="100%">
<input [ngStyle]="{'color': (this.carerForm.get('schoolCode.establishmenName').invalid && this.showErrors)  ? 'red' : 'black'}" type="text" placeholder="Start typing to search for a school or college by name ..."
matInput 
[formControl]="schoolCtrl"
[matAutocomplete]="schoolAuto"> 
</mat-form-field>

<mat-autocomplete #schoolAuto="matAutocomplete" [displayWith]="displaySchoolFn" (optionSelected)="selectedSchool($event)">
<mat-option *ngIf="isSchoolLoading" class="is-loading"><mat-spinner [diameter]="25" ></mat-spinner></mat-option>
<ng-container *ngIf="!isSchoolLoading">
<mat-option *ngFor="let s of this.schoolResults" [value]="s">
<span> {{ s.establishmenName.toLowerCase() |titlecase }} | </span>
<small>{{ s.addressLine1.toLowerCase() |titlecase }} {{ s.addressLine2.toLowerCase() |titlecase }} {{ s.addressLine3.toLowerCase() |titlecase }} {{ s.town.toLowerCase() |titlecase }} </small>
</mat-option>
</ng-container>

</mat-autocomplete>
<div fxFlex="315px" fxFlex.lt-md="100%" fxFlex.lt-sm="100%">
<button  class="single-button light-blue-button" mat-raised-button  (click)="schoolAddressLookup('manual')">Or click here to enter details manually</button>

</div>
</div>
<form *ngIf="this.schoolManual" [formGroup]="this.carerForm.get('schoolCode')">


<div   fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">

<mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
<input matInput formControlName="establishmenName" placeholder="Name">
</mat-form-field>
<mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
<input matInput formControlName="addressLine1" placeholder="Address line 1">
</mat-form-field>
<mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
<input matInput formControlName="addressLine2" placeholder="Address line 2">
</mat-form-field>
<mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
<input matInput formControlName="town" placeholder="Town/City">
</mat-form-field>
<mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
<input matInput formControlName="county" placeholder="County">
</mat-form-field>
<mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
<input matInput formControlName="postCode" placeholder="Postcode">
</mat-form-field>
</div>

</form>

</mat-card-content>
</mat-card>
<br>
</div>
  <div 
  fxLayout="row wrap" 
  fxLayout.lt-sm="column" 
  fxLayoutGap="32px" 
  fxLayoutAlign="flex-start">
  <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="nhsNumber" placeholder="NHS number">
  </mat-form-field>
  
    <a fxFlex="0 1 calc(50% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" fxLayoutAlign="left center" href="https://www.nhs.uk/nhs-services/online-services/find-nhs-number/" target="_blank" >Find your NHS Number (opens in a new window)</a>

  
  
  </div>
  
  <br>
  
  <div id="parentPanel" *ngIf="((age < 18 && relationship === 2330) || (age < 18 && relationship === 2358))" [@slideInOut]>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Parent/Guardian details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
            <input matInput formControlName="parentFirstName" placeholder="First Name" >
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
            <input matInput formControlName="parentLastName" placeholder="Last Name" >
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
            <input matInput formControlName="parentEmail" placeholder="E-mail address" >
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
            <input matInput formControlName="parentTelephone" placeholder="Telephone number">
          </mat-form-field>
       </div>
       <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <div fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <mat-checkbox #parentAddressCheck  value="this.parentAddressChecked"  (change)="parentAddressCheckBox($event)">Address is the same as the Carer</mat-checkbox>
    <mat-form-field *ngIf="!parentAddressCheck.checked"  style="width: calc(100% - 150px)"  >
      <input matInput formControlName="parentPostCode" placeholder="Postcode"> 
    </mat-form-field> 
    <button *ngIf="!parentAddressCheck.checked" class="single-button light-blue-button" mat-raised-button type="button" (click)="addressLookup('parent', this.carerForm.get('parentPostCode').value)">Lookup address</button>
  </div>
  <div *ngIf="!(parentAddressCheck.checked && this.parentAddressError)" class="glow"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
    {{ this.parentAddressError }}
    </div>
  <mat-form-field *ngIf="(this.parentAddresses?.length > 0 && !parentAddressCheck.checked)"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%"  >
    <mat-label [class.glow]="selectParentAddress.empty">Select address</mat-label>
    <mat-select   matInput #selectParentAddress id="selectParentAddress"   (selectionChange)='onParentAddressSelected(selectParentAddress.value)' > 
      <mat-option *ngFor="let item of parentAddresses let i = index"  [value]="i">
        {{item.line_1}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  </div>
  <div *ngIf="!parentAddressCheck.checked" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="parentAddressLine1" placeholder="Address line 1">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="parentAddressLine2" placeholder="Address line 2">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="parentAddressLine3" placeholder="Address line 3">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="parentCity" placeholder="Town/City">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="parentCounty" placeholder="County">
  </mat-form-field>
  </div>
      </mat-card-content>
    </mat-card>
    <br>
  </div>



  <br>

  <div *ngIf="(!this.sameCarerAddressCheck && (this.whoReferring !== 'nonCarer'))" id="gpPanel"  [@slideInOut]>
    <mat-card>
      <mat-card-header>
        <div   fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
          <mat-card-title><label class="card-title">GP Details</label></mat-card-title>
          <button *ngIf="this.gpManual" fxLayoutAlign="end center" class="single-button light-blue-button" mat-raised-button  (click)="gpAddressLookup('auto')" >Search for a GP instead</button>
  
        </div>
 
      </mat-card-header>
      <mat-card-content>
        <div *ngIf="!this.gpManual" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="15px" fxLayoutAlign="flex-start">
                   <mat-form-field fxFlex="0 1 calc(100% - 345px)" fxFlex.lt-md="100%" fxFlex.lt-sm="100%">
            <input type="text" placeholder="Start typing to search for a GP surgery by name ..."
          matInput 
          [formControl]="gpCtrl"
          [matAutocomplete]="auto"> 
        </mat-form-field>

          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayGpFn" (optionSelected)="selectedGp($event)">
            <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner [diameter]="25" ></mat-spinner></mat-option>
            <ng-container *ngIf="!isLoading">
              <mat-option *ngFor="let gp of this.gpResults" [value]="gp">
                <span>{{ gp.name.toLowerCase() |titlecase }} | </span>
                <small>{{ gp.addressLine1.toLowerCase() |titlecase }} {{ gp.addressLine2.toLowerCase() |titlecase }} {{ gp.addressLine3.toLowerCase() |titlecase }} {{ gp.addressLine4.toLowerCase() |titlecase }}</small>
              </mat-option>
            </ng-container>
            
          </mat-autocomplete>
          

        <div fxFlex="315px" fxFlex.lt-md="100%" fxFlex.lt-sm="100%">
          <button  class="single-button light-blue-button" mat-raised-button  (click)="gpAddressLookup('manual')">Or click here to enter GP details manually</button>

        </div>
      </div>
        <form *ngIf="this.gpManual" [formGroup]="this.carerForm.get('gpCode')">

        

  <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <input matInput formControlName="name" placeholder="GP Surgery name">
    </mat-form-field>
    <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <input matInput formControlName="addressLine1" placeholder="Address line 1">
    </mat-form-field>
    <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <input matInput formControlName="addressLine2" placeholder="Address line 2">
    </mat-form-field>

  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="addressLine3" placeholder="Town/City">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="addressLine4" placeholder="County">
  </mat-form-field>
  <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
    <input matInput formControlName="postCode" placeholder="Postcode">
  </mat-form-field>
  </div>
</form>
      </mat-card-content>
    </mat-card>
  </div>
  <br>
  <div id="eContactPanel" *ngIf="(age < 18)" [@slideInOut]>
    <mat-card>
      <mat-card-header>
        <mat-card-title><label class="card-title">Emergency contact details</label></mat-card-title>
      </mat-card-header>
      
      <mat-card-content>
        <mat-checkbox *ngIf="((age < 18 && relationship === 2330) || (age < 18 && relationship === 2358))" #parentEContactCheckbox  value="this.parentEContactCheckbox"  (change)="parentEContactChecked($event)">Same as Parent details</mat-checkbox>
        <br>
        <label>First emergency contact</label><br>
        <div fxLayout="row wrap" 
        fxLayout.lt-sm="column" 
        fxLayoutGap="32px" 
        fxLayoutAlign="flex-start"> 
          
          <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
            
            <input matInput formControlName="eContact1Relationship" placeholder="Name">
          </mat-form-field>
          <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
            <mat-label>E-mail address</mat-label>
            <input matInput formControlName="eContact1Email" placeholder="E-mail address">
          </mat-form-field>
          <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
            <mat-label>Telephone number</mat-label>
            <input matInput formControlName="eContact1Telephone" placeholder="Telephone number">
          </mat-form-field>
            
          </div>
          <label>Second emergency contact</label><br>
          
            <div fxLayout="row wrap" 
            fxLayout.lt-sm="column" 
            fxLayoutGap="32px" 
            fxLayoutAlign="flex-start"> 
            <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">

            <input matInput formControlName="eContact2Relationship" placeholder="Name">
          </mat-form-field>
          <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
            <input matInput formControlName="eContact2Email" placeholder="E-mail address" email="true">
          </mat-form-field>
          <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
            <input matInput formControlName="eContact2Telephone" placeholder="Telephone number">
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <br>
  </div>
  <div  fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start" >
    <div *ngIf="((this.whoReferring === 'myself' && this.index === 0)|| (this.whoReferring === 'nonCarer' && this.index === 0))" fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <label [ngStyle]="{'color': (this.carerForm.get('armedForcesShow').invalid && this.showErrors)  ? 'red' : 'black'}">Are you from a family that are current or ex members of the armed forces or emergency services, or caring for someone who is? </label><br>
      <mat-radio-group  #armedForcesShowRadioGroup formControlName="armedForcesShow" (change)="armedForcesRadioChange($event)"  > 
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    <br><br></div>    
    <div *ngIf="(this.whoReferring === 'another' || this.index > 0)" fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <label [ngStyle]="{'color': (this.carerForm.get('armedForcesShow').invalid && this.showErrors)  ? 'red' : 'black'}" >Is this {{this.carerOrPersonText}} from a family that are current or ex members of the armed forces or emergency services, or caring for someone who is?</label><br>
      <mat-radio-group  #armedForcesShowRadioGroup formControlName="armedForcesShow" (change)="armedForcesRadioChange($event)" > 
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    <br><br></div>  
  </div>
  <div *ngIf="this.carerForm.get('armedForcesShow').value === true" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start" >
    <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
      <mat-label>Select Armed Forces or Emergency Services</mat-label>
      <mat-select  #armedForcesMemberSelect matInput id="armedForcesMemberSelect" formControlName="armedForcesMember"  (selectionChange)='armedForcesMemberChange($event)'  >
        <mat-option *ngFor="let item of armedForcesMemberList" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="(this.carerForm.get('armedForcesMember').value === 2457)" fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" [@slideInOut]>
      <mat-label>Armed Force Branch</mat-label>
      <mat-select  #armedForcesBranchSelect matInput id="armedForcesBranchSelect" formControlName="armedForcesBranch">
        <mat-option *ngFor="let item of armedForcesBranchList" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="(this.carerForm.get('armedForcesMember').value === 2457)" fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" [@slideInOut]>
      <mat-label>Armed Forces Status</mat-label>
      <mat-select  #armedForcesStatusSelect matInput id="armedForcesStatusSelect" formControlName="armedForcesStatus">
        <mat-option *ngFor="let item of armedForcesStatusList" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="(this.carerForm.get('armedForcesMember').value === 2458)" fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" [@slideInOut]>
      <mat-label>Emergency Service</mat-label>
      <mat-select  #emergencyServicesBranchSelect matInput id="emergencyServicesBranchSelect" formControlName="armedForcesBranch">
        <mat-option *ngFor="let item of emergencyServicesBranchList" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="(this.carerForm.get('armedForcesMember').value === 2458)" fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" [@slideInOut]>
      <mat-label>Emergency Service Status</mat-label>
      <mat-select  #emergencyServicesStatusSelect matInput id="emergencyServicesStatusSelect" formControlName="armedForcesStatus">
        <mat-option *ngFor="let item of emergencyServicesStatusList" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <br>
  <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start" >
    <div *ngIf="(this.whoReferring === 'myself' && this.index === 0)" fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <label>Do you have a computer, tablet or mobile and regularly access the internet?</label>
 <br>
      <mat-radio-group  #hasTabletOrComputer id="hasTabletOrComputer" formControlName="hasTabletOrComputer" > 
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>    
    <div *ngIf="(this.whoReferring === 'another' || (this.index > 0 && this.whoReferring !=='nonCarer'))" fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <label>Does this {{this.carerOrPersonText}} have a computer, tablet or mobile and regularly accesses the internet?</label>
 <br>
      <mat-radio-group  #hasTabletOrComputer id="hasTabletOrComputer" formControlName="hasTabletOrComputer" > 
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>    
  </div><br>
  <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start" >
    <div *ngIf="(this.whoReferring === 'myself' && this.index === 0)" fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <label>Are you the main carer?</label>
 <br>
      <mat-radio-group  #mainCarer id="mainCarer" formControlName="mainCarer" > 
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>    
    <div *ngIf="(this.whoReferring === 'another' || (this.index > 0 && this.whoReferring !=='nonCarer'))" fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <label>Is this person the main carer?</label>
 <br>
      <mat-radio-group  #mainCarer id="mainCarer" formControlName="mainCarer" > 
        <mat-radio-button [value]="true">Yes</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
    </div>    
  </div>
  <div id="consentProAdult" *ngIf="(this.whoReferring === 'another'  && (relationship === 2330 || relationship === 2358) && (this.carerForm.get('age').value > 12 || (!this.carerForm.get('age').value && this.carerForm.get('age').value !== 0)))" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <br>
    <div fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <br>
      <label [ngStyle]="{'color': (this.carerForm.get('requestContact').invalid && this.showErrors)  ? 'red' : 'black'}">Would this Carer like an advisor to telephone them to discuss their caring needs?</label>
      <br>
        <mat-radio-group  #requestContact  formControlName="requestContact" > 
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>

    </div>
    
  </div>
  <div id="consentSelfOwn" *ngIf="(this.whoReferring === 'myself' && this.index === 0 && (this.carerForm.get('age').value > 15))" fxLayout="row wrap" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <br>
      <label [ngStyle]="{'color': (this.carerForm.get('requestContact').invalid && this.showErrors)  ? 'red' : 'black'}">Would you like an advisor to telephone you to discuss your caring needs?</label>
      <br>
        <mat-radio-group  #requestContact  formControlName="requestContact" > 
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>

    </div>
  </div>
  <div id="consentSelfOwn" *ngIf="(this.whoReferring === 'myself' && this.index > 0 && (this.carerForm.get('age').value > 15))" fxLayout="row wrap" fxLayout.lt-sm="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <div fxFlex="100%" fxFlex.lt-md="100%" fxFlex.lt-sm="100%" >
      <br>
      <label [ngStyle]="{'color': (this.carerForm.get('requestContact').invalid && this.showErrors)  ? 'red' : 'black'}">Would this Carer like an advisor to telephone them to discuss their caring needs?</label>
      <br>
        <mat-radio-group  #requestContact  formControlName="requestContact" > 
          <mat-radio-button [value]="true">Yes</mat-radio-button>
          <mat-radio-button [value]="false">No</mat-radio-button>
        </mat-radio-group>

    </div>
  </div><br>
  <div *ngIf="(this.whoReferring === 'myself' && this.index === 0)" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
    <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <mat-select   matInput #howHeardSelect id="howHeardSelect" formControlName="howDidYouHear" placeholder="How did you hear about us?" >
        <mat-option *ngFor="let item of howDidYouHearList" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="this.carerForm.get('howDidYouHear').value === 2449"  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <input matInput formControlName="howDidYouHearOther" placeholder="How did you hear about us (other)?" maxlength="250">
    </mat-form-field>

  </div>
  <!-- <button  mat-raised-button color="basic" (click)="getFormValidationErrors()">Check Validation</button> -->

   
  <br> 
  <div  *ngIf="index>0">
     <br>
    <button  mat-raised-button color="basic" (click)="delete()">Delete this {{addButtonText}}</button>
    <br><br>

   </div>
  </form>
  <mat-divider></mat-divider>
  <br>