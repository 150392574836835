
        

   <div
   fxLayout="row"
   fxLayout.xs="column" 
   fxFlexFill
   fxLayoutAlign="center center"
  >
  <div fxFlex="50">
        <h2>Thank you, your registration has been received.<br>
            <small>Your confirmation reference is: {{this.id + 1000}} </small></h2>
            <br>
   
            <br>
            <div class="button-row">

            <button *ngIf="this.refUrl" mat-raised-button color="accent" (click)="saveReferral()" >Register another</button>
            <a mat-raised-button color="primary" href="https://suffolkfamilycarers.org/wait-times/" target="_parent">Continue</a>
        </div>


    </div>
  
   



    
    
  