<mat-toolbar color="accent">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
      <mat-icon>home</mat-icon>
    </button>
    <span>Suffolk Family Carers - Events</span>
    <span class="example-spacer"></span>
    
    <!-- <img src="../../assets/images/sfcypsLogo.png" height="100px"/> -->
  </mat-toolbar>
  <div class="container-fluid box-80-width">
    <br><p><b>Hello from Suffolk Family Carers.</b><br></p>
        <div *ngIf="(this.isLoaded && this.atCapacity &&!this.alreadyResponded)">
            <b>Sorry, this event has already reached maximum capacity.</b><br><br>
        </div>

        <div *ngIf="(this.isLoaded && !this.atCapacity)">
            <p>Please let us know if you would like to attend the following event.</p>
        </div>  
    
    
    
        
        <b><div style="white-space: pre-line">{{eventData?.eventName}}</div></b>

        <label>Start: </label>
        {{this.startDate | date:'fullDate'}} at {{this.startDate | date:'shortTime'}} <br>

        <label>End: </label>
        {{this.endDate | date:'fullDate'}} at {{this.endDate | date:'shortTime'}} <br>
       

        <label>Location: </label>
        <div style="white-space: pre-line">{{eventData?.eventLocation}}</div> <br>

        <label>Description: </label>
        <div style="white-space: pre-line">{{eventData?.eventDescription}}</div>



    <br><br>

    <div *ngIf="(this.isLoaded && this.atCapacity &&!this.alreadyResponded)">
        <b>Sorry, this event has already reached maximum capacity.</b>
    </div>

    <div *ngIf="(this.isLoaded && this.alreadyResponded)">
        <b>This invitation has already been accepted or declined.</b>
        <p>If you need to contact us, please visit <a href="https://suffolkfamilycarers.org/about-us/contact-us/" target="_parent">Contact Us | Suffolk Family Carers</a>
        </p>
    </div>

  <form *ngIf="(!this.completedRef && !this.atCapacity&& this.isLoaded && !this.alreadyResponded)" [formGroup]="this.eventForm">
    <p><b>Please select:</b></p>
    <div>
      <!-- <button  mat-raised-button (click)="onToggleChange(true)">Yes, attending this event</button> 
      <button  mat-raised-button (click)="onToggleChange(false)">No, not attending</button> -->


    <section>
        <mat-button-toggle-group (change)="onToggleChange($event.value)" formControlName="Response" aria-label="Are you comming" class="fit-width">
          <mat-button-toggle  [value]="true">Yes, attending this event</mat-button-toggle>
          <mat-button-toggle   [value]="false">No, not attending</mat-button-toggle>
        </mat-button-toggle-group>
      </section>
    </div>
    <br>

    <div id="formStart" #formStart *ngIf="this.eventForm.get('Response').value">
        <br><p>To confirm your registration please complete the following details.<br>
            </p>
            
          
    
        <div class="row">
            <div class="col-md-6">
          <mat-form-field appearance="outline" class="box-full-width" >
            <mat-label>Name of participant(s)</mat-label>
            <input matInput formControlName="NameOfParticipants" required>
          </mat-form-field>
            </div>
            <div class="col-md-6">
          <mat-form-field appearance="outline" class="box-full-width">
            <mat-label>Contact number (if over 16 years)</mat-label>
            <input matInput formControlName="ContactNumber" >
          </mat-form-field>
            </div>
            </div>
            
            <div class="row">
                <div class="col-md-12">
                <p>If there’s an emergency, please contact:</p>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline" class="box-full-width">
                      <mat-label>Name</mat-label>
                      <input matInput formControlName="EmergencyContactName" required>
                    </mat-form-field>
                      </div>
                      <div class="col-md-4">
                        <mat-form-field appearance="outline" class="box-full-width">
                          <mat-label>Relationship</mat-label>
                          <input matInput formControlName="EmergencyContactRelationship" required>
                        </mat-form-field>
                          </div>
                          <div class="col-md-4">
                            <mat-form-field appearance="outline" class="box-full-width">
                              <mat-label>Contact number</mat-label>
                              <input matInput formControlName="EmergencyContactNumber" required>
                            </mat-form-field>
                              </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <mat-form-field appearance="outline" class="box-full-width" >
                      <mat-label>Health & Medication Needs</mat-label>
                      <input matInput formControlName="HealthMedicalNeeds" placeholder="For example 'Has asthma and will bring inhaler'" >
                    </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="box-full-width" >
                  <mat-label>Dietary requirements</mat-label>
                  <input matInput formControlName="DietaryRequirements" >
                </mat-form-field>
        </div>
        <div class="col-md-12">
            <mat-form-field appearance="outline" class="box-full-width" >
              <mat-label>Other information that will help us to support the participant(s) during the activity</mat-label>
              <input matInput formControlName="OtherInfo">
            </mat-form-field>
    </div>
        
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label><b>Optional Photo & Video Consent</b></label><br>
                    <mat-checkbox labelPosition="right"   formControlName="PhotoVideoConsent">I give Suffolk Family Carers permission to take photographs and or videos of  {{eventForm.controls.NameOfParticipants.value}}
                        and grant full rights to use the images resulting from the photography/filming and any reproductions or adaptions of the images for fundraising, publicity or other purposes to help achieve the organisations aims. 
                    <br>
                    I confirm I consent for the images /films to be used by Suffolk Family Carers in the following ways:
                        <ul>
<li>Use on Suffolk Family Carers Website & Social Media</li>
<li>Printed Publicity Materials</li>
<li>By other agencies delivering respite in partnership with Suffolk Family Carers</li>
<li>Press Releases, Funding applications/reporting to funders</li>
                        </ul></mat-checkbox>


                </div>
                <div class="col-md-12">
                    <label>By completing this form I understand that I am consenting to the following:
                        <ul>
                            <li>Suffolk Family Carers keeping a record of this form for health and safety reasons</li>
                            <li>Suffolk Family Carers providing medical first aid treatment in an emergency</li>
                            <li>Participants to follow the behaviour agreement and safety rules at activity </li>
                        </ul>
                        </label>
                        <mat-form-field appearance="outline" class="box-full-width" >
                            <mat-label>Form completed by (full name)</mat-label>
                            <input matInput formControlName="CompletedBy" required>
                          </mat-form-field>
                </div>
            </div>
 
 

<div class="col-md-12">
    <div *ngIf="(this.eventForm.invalid)" >
      <br>
       <b>Please complete all required details before continuing.</b> 
       <br><br>
    </div>
    <button [disabled]="this.saving || this.eventForm.invalid" type="submit" mat-raised-button color="accent" (click)="submitResponse()" >{{this.saveButtonText}}</button>
    <br><br>
  </div>
</div>
    </form>
</div>
    <!-- <pre > {{eventForm.value | json}} </pre> -->
    <!-- <div id="declineResult" *ngIf="this.eventForm.get('Response').value===false && !this.declinedRef" class="container" #declineResult>
      <div
      fxLayout="row"
      fxLayout.xs="column" 
      fxFlexFill
      fxLayoutAlign="center center"
     >
     <div fxFlex="50">
       <br>
           <h2>Please wait...</h2><br>
           <p>while we submit your response.</p>
                   </div>
    </div>
    <br><br>
    </div> -->
    
    <div id="declineResult" *ngIf="this.eventForm.get('Response').value===false" class="container" #declineResult>
        <div
        fxLayout="row"
        fxLayout.xs="column" 
        fxFlexFill
        fxLayoutAlign="center center"
       >
       <div fxFlex="50">
         <br>
             <h2>Thank you for letting us know you won't be attending.</h2><br>
                 <br>
                 <br>
                 <p>If you need to contact us, please visit <a href="https://suffolkfamilycarers.org/about-us/contact-us/" target="_parent">Contact Us | Suffolk Family Carers</a>
                  </p>
                 <br>
                 <br>
                 <a mat-raised-button color="primary" href="https://suffolkfamilycarers.org" target="_parent">Continue to homepage</a>
         </div>
      </div>
      <br><br>
    </div>
    <div id="completeResult" *ngIf="this.completedRef" #complete>
      <div
      fxLayout="row"
      fxLayout.xs="column" 
      fxFlexFill
      fxLayoutAlign="center center"
     >
     <div fxFlex="50">
       <br>
           <h2>Thank you for confirming<br>
               <small>Your confirmation reference is: {{this.completedRef + 1000}} </small></h2>
               <br>
               <br>
               <p>If you need to contact us, please visit <a href="https://suffolkfamilycarers.org/about-us/contact-us/" target="_parent">Contact Us | Suffolk Family Carers</a>
                </p>
               <br>
               <br>
               <a mat-raised-button color="primary" href="https://suffolkfamilycarers.org" target="_parent">Continue to homepage</a>
       </div>
    </div>
    </div>
  

  