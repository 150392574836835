<form [formGroup]="caredForForm">
  <div  *ngIf="index===0">
    <mat-divider></mat-divider>
    <h3>Cared For's details</h3>
  </div>
  <div  *ngIf="index>0">
    <mat-divider></mat-divider>
    <h3>{{index+1 | ordinal}} Cared For's details</h3>
  </div>
  <div   fxLayout="row wrap"   fxLayout.lt-sm="column"   fxLayoutGap="32px"   fxLayoutAlign="flex-start">
    <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <mat-label>Title</mat-label>
      <mat-select  #carerTitleSelect matInput id="carerTitleSelect" formControlName="title">
        <mat-option *ngFor="let item of titles" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <input matInput formControlName="firstName" placeholder="First Name">
    </mat-form-field>
    
    <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <input matInput formControlName="lastName" placeholder="Last Name">
    </mat-form-field>
    
    <mat-form-field [ngStyle]="{'color': (!this.caredForForm.valid && this.showErrors)  ? 'red' : ''}" fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <mat-label [ngStyle]="{'color': (!this.caredForForm.valid && this.showErrors)  ? 'red' : ''}" >Date of birth</mat-label>
      <input [ngStyle]="{'color': (!this.caredForForm.valid && this.showErrors)  ? 'red' : ''}" matInput [formControl]="dobControl" placeholder="DD/MM/YYYY" [matDatepicker]="picker" (ngModelChange)="term$.next($event)" >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field   fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <mat-label [ngStyle]="{'color': (!this.caredForForm.valid && this.showErrors)  ? 'red' : ''}" >Age Range (If DOB not known)</mat-label>
      <mat-select   matInput #ageRangeSelect id="ageRangeSelect" formControlName="ageRange" >
        <mat-option *ngFor="let item of ageRanges" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field   fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
      <mat-select   matInput #genderSelect id="genderSelect" formControlName="gender" placeholder="Gender" >
        <mat-option *ngFor="let item of genders" [value]="item.value">
          {{item.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div id="negativeAgeWarning" *ngIf="(this.age < 0)" fxLayout="row wrap" 
    fxLayout.lt-sm="column" 
    fxLayoutGap="32px" 
    fxLayoutAlign="flex-start"
    class="glow" >
    Sorry, the Date of Birth cannot be in the future, or the Cared For is too young. Did you mean {{this.suggestDate}}?
    </div>
  </div>
    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start">
      
      <div fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%"> 
        <mat-checkbox #addressCheck  value="this.addressChecked"  (change)="addressCheckBox($event)">Address is the same as the First Carer</mat-checkbox>
        <mat-form-field style="width: calc(100% - 150px)"  >
          <input matInput #postCodeTextBox formControlName="postCode" placeholder="Postcode"> 
        </mat-form-field> 
        <!--
          <button *ngIf="!addressCheck.checked" class="single-button light-blue-button" mat-raised-button type="button" (click)="addressLookup('caredFor', this.caredForForm.get('postCode').value)">Lookup address</button>
        -->
      </div>
      <mat-form-field *ngIf="this.carerdForAddresses?.length > 0 && !addressCheck.checked"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
        <mat-label [class.glow]="selectAddress.empty">Select an address</mat-label>
        <mat-select   matInput #selectAddress id="selectAddress"    (selectionChange)='onAddressSelected(selectAddress.value)'> 
          <mat-option *ngFor="let item of carerdForAddresses let i = index"  [value]="i">
            {{item.line_1}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="(this.carerdForAddressesError && !addressCheck.checked)" class="glow"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%" >
        {{ this.carerdForAddressesError }}
        </div>
      <mat-form-field *ngIf="addressCheck.checked"  fxFlex="0 1 calc(33.3% -32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
        <mat-select   matInput #selectCarerAddress id="selectCarerAddress"  placeholder="Select address"  (selectionChange)='onCarerAddressSelected(selectCarerAddress.value)'> 
          <mat-option *ngFor="let carer  of this.carerForm.controls let i = index"  [value]="i">
            {{carer.get('addressLine1').value + ", " + carer.get('city').value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>

      <div  *ngIf="!addressCheck.checked" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start" >
        <mat-form-field  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
          <mat-select   matInput #doesThisPersonLive id="doesThisPersonLive" formControlName="doesThisPersonLive" placeholder="Does this person live">
            <mat-option *ngFor="let item of caredForLocations" [value]="item.value">
              {{item.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="(this.caredForForm.get('doesThisPersonLive').value !== 2452 && this.caredForForm.get('doesThisPersonLive').value !== 2453)"  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
          <input matInput formControlName="travelToAddress" placeholder="How much travelling time is involved in visiting this person (mins)" maxlength="200">
        </mat-form-field>
      </div>
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="32px" fxLayoutAlign="flex-start" >
        <mat-form-field  fxFlex="0 1 calc(100% - 32px)" fxFlex.lt-md="0 1 calc(100% - 32px)" fxFlex.lt-sm="100%">
          <mat-chip-list #chipList aria-label="Cared for Medical Conditions" >
            <mat-chip *ngFor="let item of conditionsArray" 
            [selectable]="selectable" [removable]="removable" (removed)="remove(item)" >
            {{item.viewValue}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input matInput #cfConditionsInput [formControl]="conditionsCtrl" placeholder="Medical Conditions (start typing and select from the list, or type your own and press Enter after each entry)"
          [matAutocomplete]="cfAuto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)" [matChipInputAddOnBlur]=true maxlength="135" >
          </mat-chip-list>
          
        
        <mat-autocomplete #cfAuto="matAutocomplete"  (optionSelected)="selected($event)">
          <mat-option *ngFor="let option of filteredOptions  | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

        
      </div>

 

  <br>
  
  <div  *ngIf="index>0">
    <button  mat-raised-button color="basic" (click)="delete()">Delete this Cared For</button>
    <br><br>
   </div>
</form>
<br>