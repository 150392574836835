import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { stringify } from 'querystring';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponentComponent } from '../dialog-component/dialog-component.component';
import { AppSettings } from '../AppSetting';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import * as moment from 'moment';


interface MfLookup {
  value: number;
  viewValue: string;
}



@Component({
  selector: 'app-yps-assessment',
  templateUrl: './yps-assessment.component.html',
  styleUrls: ['./yps-assessment.component.scss']
})
export class YpsAssessmentComponent implements OnInit {

  

  ypsAssessmentForm = new FormGroup({
    assessmentGuid: new FormControl(''),
    carerGuid: new FormControl(''),

    firstName: new FormControl(''),
    lastName: new FormControl(''),
    dob: new FormControl(''),
    // mobile: new FormControl(''),
    // email: new FormControl(''),
    gender: new FormControl(''),
    educationStatus: new FormControl(''),
    schoolCollege: new FormControl(''),
    q1InfoToShare: new FormControl(''),
    q2IThinkBecause: new FormControl(''),
    q4Changes: new FormControl(''),
    formComments: new FormControl(),
    maca1: new FormControl(null),
    maca2: new FormControl(null),
    maca3: new FormControl(''),
    maca4: new FormControl(''),
    maca5: new FormControl(''),
    maca6: new FormControl(''),
    maca7: new FormControl(''),
    maca8: new FormControl(''),
    maca9: new FormControl(''),
    maca10: new FormControl(''),
    maca11: new FormControl(''),
    maca12: new FormControl(''),
    maca13: new FormControl(''),
    maca14: new FormControl(''),
    maca15: new FormControl(''),
    maca16: new FormControl(''),
    maca17: new FormControl(''),
    maca18: new FormControl(''),
    // maca1Comments: new FormControl(''),
    // maca2Comments: new FormControl(''),
    // maca3Comments: new FormControl(''),
    // maca4Comments: new FormControl(''),
    // maca5Comments: new FormControl(''),
    // maca6Comments: new FormControl(''),
    // maca7Comments: new FormControl(''),
    // maca8Comments: new FormControl(''),
    // maca9Comments: new FormControl(''),
    // maca10Comments: new FormControl(''),
    // maca11Comments: new FormControl(''),
    // maca12Comments: new FormControl(''),
    // maca13Comments: new FormControl(''),
    // maca14Comments: new FormControl(''),
    // maca15Comments: new FormControl(''),
    // maca16Comments: new FormControl(''),
    // maca17Comments: new FormControl(''),
    // maca18Comments: new FormControl(''),
    panoc1: new FormControl(''),
    panoc2: new FormControl(''),
    panoc3: new FormControl(''),
    panoc4: new FormControl(''),
    panoc5: new FormControl(''),
    panoc6: new FormControl(''),
    panoc7: new FormControl(''),
    panoc8: new FormControl(''),
    panoc9: new FormControl(''),
    panoc10: new FormControl(''),
    panoc11: new FormControl(''),
    panoc12: new FormControl(''),
    panoc13: new FormControl(''),
    panoc14: new FormControl(''),
    panoc15: new FormControl(''),
    panoc16: new FormControl(''),
    panoc17: new FormControl(''),
    panoc18: new FormControl(''),
    panoc19: new FormControl(''),
    panoc20: new FormControl(''),
    // panoc1Comments: new FormControl(''),
    // panoc2Comments: new FormControl(''),
    // panoc3Comments: new FormControl(''),
    // panoc4Comments: new FormControl(''),
    // panoc5Comments: new FormControl(''),
    // panoc6Comments: new FormControl(''),
    // panoc7Comments: new FormControl(''),
    // panoc8Comments: new FormControl(''),
    // panoc9Comments: new FormControl(''),
    // panoc10Comments: new FormControl(''),
    // panoc11Comments: new FormControl(''),
    // panoc12Comments: new FormControl(''),
    // panoc13Comments: new FormControl(''),
    // panoc14Comments: new FormControl(''),
    // panoc15Comments: new FormControl(''),
    // panoc16Comments: new FormControl(''),
    // panoc17Comments: new FormControl(''),
    // panoc18Comments: new FormControl(''),
    // panoc19Comments: new FormControl(''),
    // panoc20Comments: new FormControl(''),
    
  });
  //Setup debounce
  term$ = new Subject<string>();
  private searchSubscription: Subscription;

  age: number;
  public birthDate: string;
  
  saving: boolean;
  saveButtonText: string;
  referralGuid: string = this.route.snapshot.queryParamMap.get('guid');
  carerGuid: string = this.route.snapshot.queryParamMap.get('carer');
  completedRef: number;
  dobControl = new FormControl();
  genders: MfLookup[] = AppSettings.Gender;
  educationStatusList: MfLookup[] = AppSettings.EducationStatus

  constructor(private route: ActivatedRoute, public dialog: MatDialog, private apiService: ApiService, private chRef: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.searchSubscription = this.term$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => {
        console.log('text changed')
        if (this.dobControl.valid)
        {
          this.CalculateAge();
        }
        return EMPTY;
      })
    ).subscribe();

    const ypsAssessmentForm = null;
    this.saveButtonText = 'Submit assessment';
    console.log(this.referralGuid); 
    this.ypsAssessmentForm.controls['assessmentGuid'].setValue(this.referralGuid);
    this.ypsAssessmentForm.controls['carerGuid'].setValue(this.carerGuid);

  }


  public CalculateAge(): void {
    
    this.birthDate = this.dobControl.value;
    if (this.birthDate) {
    
      //Moment date to string
      var momentObj = moment(this.dobControl.value);
      var momentString = momentObj.format('DD/MM/YYYY');
      this.ypsAssessmentForm.get('dob').setValue(momentString);
      var timeDiff = Math.abs(Date.now() - new Date(this.birthDate).getTime());
      this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      
    
    }
    


    }
  submitAssessment() {
    console.log('Assessment saving')

    this.ypsAssessmentForm.controls['maca1'].setValue(+this.ypsAssessmentForm.controls['maca1'].value);
    this.ypsAssessmentForm.controls['maca2'].setValue(+this.ypsAssessmentForm.controls['maca2'].value);
    this.ypsAssessmentForm.controls['maca3'].setValue(+this.ypsAssessmentForm.controls['maca3'].value);
    this.ypsAssessmentForm.controls['maca4'].setValue(+this.ypsAssessmentForm.controls['maca4'].value);
    this.ypsAssessmentForm.controls['maca5'].setValue(+this.ypsAssessmentForm.controls['maca5'].value);
    this.ypsAssessmentForm.controls['maca6'].setValue(+this.ypsAssessmentForm.controls['maca6'].value);
    this.ypsAssessmentForm.controls['maca7'].setValue(+this.ypsAssessmentForm.controls['maca7'].value);
    this.ypsAssessmentForm.controls['maca8'].setValue(+this.ypsAssessmentForm.controls['maca8'].value);
    this.ypsAssessmentForm.controls['maca9'].setValue(+this.ypsAssessmentForm.controls['maca9'].value);
    this.ypsAssessmentForm.controls['maca10'].setValue(+this.ypsAssessmentForm.controls['maca10'].value);
    this.ypsAssessmentForm.controls['maca11'].setValue(+this.ypsAssessmentForm.controls['maca11'].value);
    this.ypsAssessmentForm.controls['maca12'].setValue(+this.ypsAssessmentForm.controls['maca12'].value);
    this.ypsAssessmentForm.controls['maca13'].setValue(+this.ypsAssessmentForm.controls['maca13'].value);
    this.ypsAssessmentForm.controls['maca14'].setValue(+this.ypsAssessmentForm.controls['maca14'].value);
    this.ypsAssessmentForm.controls['maca15'].setValue(+this.ypsAssessmentForm.controls['maca15'].value);
    this.ypsAssessmentForm.controls['maca16'].setValue(+this.ypsAssessmentForm.controls['maca16'].value);
    this.ypsAssessmentForm.controls['maca17'].setValue(+this.ypsAssessmentForm.controls['maca17'].value);
    this.ypsAssessmentForm.controls['maca18'].setValue(+this.ypsAssessmentForm.controls['maca18'].value);
    this.ypsAssessmentForm.controls['panoc1'].setValue(+this.ypsAssessmentForm.controls['panoc1'].value);
    this.ypsAssessmentForm.controls['panoc2'].setValue(+this.ypsAssessmentForm.controls['panoc2'].value);
    this.ypsAssessmentForm.controls['panoc3'].setValue(+this.ypsAssessmentForm.controls['panoc3'].value);
    this.ypsAssessmentForm.controls['panoc4'].setValue(+this.ypsAssessmentForm.controls['panoc4'].value);
    this.ypsAssessmentForm.controls['panoc5'].setValue(+this.ypsAssessmentForm.controls['panoc5'].value);
    this.ypsAssessmentForm.controls['panoc6'].setValue(+this.ypsAssessmentForm.controls['panoc6'].value);
    this.ypsAssessmentForm.controls['panoc7'].setValue(+this.ypsAssessmentForm.controls['panoc7'].value);
    this.ypsAssessmentForm.controls['panoc8'].setValue(+this.ypsAssessmentForm.controls['panoc8'].value);
    this.ypsAssessmentForm.controls['panoc9'].setValue(+this.ypsAssessmentForm.controls['panoc9'].value);
    this.ypsAssessmentForm.controls['panoc10'].setValue(+this.ypsAssessmentForm.controls['panoc10'].value);
    this.ypsAssessmentForm.controls['panoc11'].setValue(+this.ypsAssessmentForm.controls['panoc11'].value);
    this.ypsAssessmentForm.controls['panoc12'].setValue(+this.ypsAssessmentForm.controls['panoc12'].value);
    this.ypsAssessmentForm.controls['panoc13'].setValue(+this.ypsAssessmentForm.controls['panoc13'].value);
    this.ypsAssessmentForm.controls['panoc14'].setValue(+this.ypsAssessmentForm.controls['panoc14'].value);
    this.ypsAssessmentForm.controls['panoc15'].setValue(+this.ypsAssessmentForm.controls['panoc15'].value);
    this.ypsAssessmentForm.controls['panoc16'].setValue(+this.ypsAssessmentForm.controls['panoc16'].value);
    this.ypsAssessmentForm.controls['panoc17'].setValue(+this.ypsAssessmentForm.controls['panoc17'].value);
    this.ypsAssessmentForm.controls['panoc18'].setValue(+this.ypsAssessmentForm.controls['panoc18'].value);
    this.ypsAssessmentForm.controls['panoc19'].setValue(+this.ypsAssessmentForm.controls['panoc19'].value);
    this.ypsAssessmentForm.controls['panoc20'].setValue(+this.ypsAssessmentForm.controls['panoc20'].value);

      console.log(this.ypsAssessmentForm.getRawValue())

      
     //this.saving = true;
    this.saveButtonText = "Submitting - Please wait";
      this.apiService.submitYpsAssessment(this.ypsAssessmentForm.getRawValue())
      .subscribe((data) => {
        this.completedRef = data
        
        
      },
      error => { console.log(error);
        this.saveButtonText = 'Submit Registration';
        //this.submitError = "Sorry, there was a problem submitting your details. Please try again."
        this.saving = false;
        //this.chRef.detectChanges();
        this.dialog.open(DialogComponentComponent);
       
      });
    
      this.scrollToTop();
    }

  scrollToTop() {

    (function smoothscroll() {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
          window.requestAnimationFrame(smoothscroll);
          window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
  })();  }
    



   
}

  

