export class CaredFor {
    guid: string
    title: number
    firstName: string
    lastName: string
    dateOfBirth: string
    ageRange: number
    postCode: string
    addressLine1: string
    addressLine2: string
    addressLine3: string
    city: string
    county: string
    cfConditions: MfLookup[];
    doesThisPersonLive: number;
    travelToAddress: string;
    gender: number;


    constructor(guid?: string) {
        this.guid = guid
    }
}



interface MfLookup {
    value: number;
    viewValue: string;
  }