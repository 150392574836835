import { Component, OnInit, Input, Output, ChangeDetectionStrategy, EventEmitter, ElementRef, ViewChild} from '@angular/core'
import { FormGroup, FormArray, FormControl, ValidatorFn, AbstractControl, ValidationErrors, Validators } from '@angular/forms'
import {AppSettings} from '../AppSetting';
import { ApiService } from '../api.service';
import { ChangeDetectorRef } from '@angular/core';
import { findReadVarNames } from '@angular/compiler/src/output/output_ast';
import { Carer } from '../form-carer-details';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable, Subject, Subscription, EMPTY} from 'rxjs';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith, debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';
import * as moment from 'moment';
//import { OrdinalPipe } from '../orginal.pipe';



interface MfLookup {
  value: number;
  viewValue: string;
}



interface CarerAddresses {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  county: string;
  postCode: string;
}

@Component({
  selector: 'app-form-cared-for',
  templateUrl: './form-cared-for.component.html',
  styleUrls: ['./form-cared-for.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormCaredForComponent implements OnInit {

  private allowFreeTextAddConditions = true;

  
  filteredOptions: Observable<String[]>;
  conditionsArray: MfLookup[] = [];

  @Input() caredForForm: FormGroup
  @Input() index: number
  @Input() carerForm: FormArray
  @Input() whoReferring: string
  @Output() deleteCaredFor: EventEmitter<number> = new EventEmitter()
  @Input() showErrors: boolean;
  

  titles: MfLookup[] = AppSettings.Title; 
  ageRanges: MfLookup[] = AppSettings.AgeRanges;
  caredForLocations: MfLookup[] = AppSettings.CaredForLocations;
  genders: MfLookup [] = AppSettings.Gender;

  medicalConditionList: MfLookup[] = AppSettings.MedicalConditions
  carerAddresses: CarerAddresses[];
  carerdForAddresses: any;
  carerdForAddressesError: string;
  conditionsCtrl = new FormControl();
  addressChecked: boolean;
  dobControl = new FormControl();
  stepValid: boolean
  suggestDate: string;


    //Setup debounce
    term$ = new Subject<string>();
    private searchSubscription: Subscription;

    age: number;
    public birthDate: string;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('cfConditionsInput') cfConditionsInput: ElementRef<HTMLInputElement>;
  @ViewChild('cfAuto') matAutocomplete: MatAutocomplete;

  constructor(private apiService: ApiService, private chRef: ChangeDetectorRef) {
   
    
  }

  add(event: MatChipInputEvent): void {

    if (!this.allowFreeTextAddConditions) {
      console.log('allowFreeTextAddConditions is false');
      return;
    }

    if (this.matAutocomplete.isOpen) {
      return;
    }

     // Add our condition
     const value = event.value;
     if ((value || '').trim()) {
      this.selectConditionByName(value.trim());
    }

    this.resetInputs();

    }

  remove(idToRemove: MfLookup): void {
    const index = this.conditionsArray.indexOf(idToRemove);

    if (index >= 0) {
      this.conditionsArray.splice(index, 1);
    }
    this.caredForForm.controls.cfConditions.setValue(this.conditionsArray)

  }


  selected(event: MatAutocompleteSelectedEvent): void {
    
    this.selectConditionByName(event.option.value);
    this.resetInputs();

  }

  private resetInputs() {
    this.cfConditionsInput.nativeElement.value = '';
    this.conditionsCtrl.setValue(null); 
  }

  private selectConditionByName(conditionName) {
    let foundCondition= this.medicalConditionList.filter(condition => condition.viewValue == conditionName);
    if (foundCondition.length) {
      
      this.conditionsArray.push(foundCondition[0]);
    } else {
      
      this.conditionsArray.push({ value: Math.random(), viewValue: conditionName });
    }
    this.caredForForm.controls.cfConditions.setValue(this.conditionsArray)

  }


  ngOnInit(): void {
    

    this.filteredOptions = this.conditionsCtrl.valueChanges.pipe(
      startWith(null),
      map(conditionName => this.filterOnValueChange(conditionName))
    );
    this.searchSubscription = this.term$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => {
        console.log('text changed')
        if (this.dobControl.valid)
        {
          this.CalculateAge();
        }
        return EMPTY;
      })
    ).subscribe();


    // this.filteredOptions = this.conditionsCtrl.valueChanges.pipe(
    //  startWith(''),
    //  map((condition: string | null) => condition ? this._filter(condition) : this.medicalConditionList.slice()));


      // this.filteredOptions = this.conditionsCtrl.valueChanges.pipe(
      //   startWith(''),
      //   map(value => typeof value === 'string' ? value : value.name),
      //   map(name => name ? this._filter(name) : this.medicalConditionList.slice())
      // );
  }

  private filterOnValueChange(conditionName: string | null): String[] {
    let result: String[] = [];

    let allLessSelected = this.medicalConditionList.filter(condition => this.conditionsArray.indexOf(condition) < 0);
    if (conditionName) {
      result = this.filterConditions(allLessSelected, conditionName);
    } else {
      result = allLessSelected.map(condition => condition.viewValue);
    }
    return result;
  }

  private filterConditions(conditionList: MfLookup[], conditionName: String): String[] {
    let filteredConditionList: MfLookup[] = [];
    const filterValue = conditionName.toLowerCase();
    let conditionMatchingConditionName = conditionList.filter(condition => condition.viewValue.toLowerCase().indexOf(filterValue) > -1 );
    if (conditionMatchingConditionName.length || this.allowFreeTextAddConditions) {
      //
      // either the engineer name matched some autocomplete options 
      // or the name didn't match but we're allowing 
      // non-autocomplete engineer names to be entered
      //
      filteredConditionList = conditionMatchingConditionName;
    } else {
      //
      // the engineer name didn't match the autocomplete list 
      // and we're only allowing engineers to be selected from the list
      // so we show the whjole list
      // 
      filteredConditionList = conditionList;
    }
    //
    // Convert filtered list of engineer objects to list of engineer 
    // name strings and return it
    //
    return filteredConditionList.map(condition => condition.viewValue);
  }
  public CalculateAge(): void {
    
    this.birthDate = this.dobControl.value;
    if (this.birthDate) {
      this.caredForForm.get('age').setValidators(Validators.required)
      //Moment date to string
      var momentObj = moment(this.dobControl.value);
      var momentString = momentObj.format('DD/MM/YYYY');
      this.caredForForm.get('dateOfBirth').setValue(momentString);
      
      var now = moment().diff(momentObj, 'days', true)
      
      if (now <0)
      { this.age = -1;
        console.log("Date is in the future");

        var subDate = momentObj.subtract(100, 'years');
        
        var formatSubDate = subDate.format('DD/MM/YYYY');
        
        this.suggestDate = subDate.format('DD/MM/YYYY');
        console.log(this.suggestDate)
        this.caredForForm.get('age').setValue(null);
      }
      else{
        //var timeDiff = Math.abs(Date.now() - new Date(this.birthDate).getTime());
        //this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

        this.age = moment().diff(momentObj, 'years');

        this.caredForForm.get('age').setValue(this.age);

        this.chRef.detectChanges();
        this.caredForForm.get('ageRange').updateValueAndValidity;

      }
    
    }
    else{
      this.caredForForm.get('age').clearValidators();
      this.caredForForm.get('age').updateValueAndValidity();

    }
    


    }
  
  delete() {
    this.deleteCaredFor.emit(this.index)
  }

  addressCheckBox(event){
    console.log(this.addressChecked); //true or false
    console.log("index: " + this.index)
    if (event.checked === true)
    {
      console.log('true is called')
    // this.caredForForm.controls.addressLine1.disable()
    // this.caredForForm.controls['addressLine2'].disable()
    // this.caredForForm.controls['addressLine3'].disable()
    // this.caredForForm.controls['city'].disable()
    // this.caredForForm.controls['county'].disable()
    this.caredForForm.controls['postCode'].disable()
    this.onCarerAddressSelected(0);
    // for (let  carer  of this.carerForm.controls) {
    //    carer.get('firstName').value
    // }
    }
    else{
      console.log('false is called')
      // this.caredForForm.controls['addressLine1'].enable()
      // this.caredForForm.controls['addressLine2'].enable()
      // this.caredForForm.controls['addressLine3'].enable()
      // this.caredForForm.controls['city'].enable()
      // this.caredForForm.controls['county'].enable()
      this.caredForForm.controls['postCode'].enable()

    }
   }

  addressLookup(addressType: string, postCode: string){
    console.log("index: " + this.index)

    this.carerdForAddresses = null;
    this.carerdForAddressesError = null;

    var addressVar;
    var re = / ,/g
    //var postcode: string;
    //postcode = this.carerForm.get('postCode').value;
    console.log('Address lookup starting with ' + postCode)
    this.apiService.getAddress(postCode).subscribe((data)=>{ 
    addressVar = data['addresses'];
    if (addressType === "caredFor")
    {
      console.log('Carer For address lookup')
      this.carerdForAddresses = addressVar
      console.log(addressVar)
    }
    this.chRef.detectChanges();
      
    
    },
    error => { console.log(error);
     
      this.carerdForAddressesError = "Sorry, that postcode wasn't found. Please check or enter the address manually."
      this.chRef.detectChanges();
  

     
    })
   

  }
  onAddressSelected(value:number)
  {
    console.log("index: " + this.index)

    var address = this.carerdForAddresses;
    //console.log(address.length)
    this.caredForForm.controls.addressLine1.setValue(address[value].line_1)
    this.caredForForm.controls.addressLine2.setValue(address[value].line_2)
    this.caredForForm.controls.addressLine3.setValue(address[value].line_3)
    this.caredForForm.controls.city.setValue(address[value].town_or_city)
    this.caredForForm.controls.county.setValue(address[value].county)
    
  }
  onCarerAddressSelected(value:number)
  {
    console.log("index: " + this.index)

    console.log(value)
    var address = this.carerForm.controls;
    //console.log(address.length)
    // this.caredForForm.controls.addressLine1.setValue(address[value].get('addressLine1').value)
    // this.caredForForm.controls.addressLine2.setValue(address[value].get('addressLine2').value)
    // this.caredForForm.controls.addressLine3.setValue(address[value].get('addressLine3').value)
    // this.caredForForm.controls.city.setValue(address[value].get('city').value)
    // this.caredForForm.controls.county.setValue(address[value].get('county').value)
    this.caredForForm.controls.postCode.setValue(address[value].get('postCode').value)

  }

  checkCarers(){
    for (let  carer  of this.carerForm.controls) {
    console.log(carer.get('firstName').value)
  }
}

}

