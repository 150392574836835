import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-yps-assessment-form-panoc',
  templateUrl: './yps-assessment-form-panoc.component.html',
  styleUrls: ['./yps-assessment-form-panoc.component.scss']
})
export class YpsAssessmentFormPanocComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
