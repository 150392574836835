<form *ngIf="!this.completedRef" [formGroup]="referralForm">
  <!-- <form  [formGroup]="referralForm"> -->
  <div id="pre-header"></div>
  <mat-horizontal-stepper linear #stepper id="step-header" (selectionChange)="setIndex($event)">
    <mat-step *ngIf="this.referralSource !== 'ndpeer'" label="Welcome" [stepControl]="this.referralForm.get('referredBy')"  id="topStep">
     
      <p>To get started, please tell us if you are registering yourself and others or the full registration is on behalf of somebody else.</p>
      <div 
      fxLayout="row wrap" 
      fxLayout.lt-sm="column" 
      fxLayoutGap="32px" 
      fxLayoutAlign="flex-start">

      <mat-form-field fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
        <mat-label>Registering on behalf of</mat-label>
        <mat-select #refferBySelect matInput id="referredByInput" formControlName="referredBy"
          [(value)]="this.whoReferring" required (selectionChange)='onOptionsSelected(refferBySelect.value)' >
                
          <mat-option value="myself">Myself</mat-option>
          <mat-option value="another">Another Person</mat-option>


        </mat-select>
        
      </mat-form-field>

      <mat-form-field class="invisible"  *ngIf="this.referralSource"  fxFlex="0 1 calc(33.3% - 32px)" fxFlex.lt-md="0 1 calc(50% - 32px)" fxFlex.lt-sm="100%">
   
        <input matInput formControlName="referralUrl" placeholder="Referral form link used:">
      </mat-form-field>
      
      </div><br>
          <br>
      <button mat-raised-button color="primary" matStepperNext type="button">Next</button>
      <div *ngIf="this.referralForm.get('referredBy').status==='INVALID'">
        <br>
         <small>Please complete all required fields in this section before continuing.</small> 
      </div>
    </mat-step>
    <mat-step *ngIf="this.whoReferring ==='another'" label="Your details"  [stepControl]="this.referralForm.get('yourDetails')" > 
    
    <app-your-details-form [yourDetailsForm]="yourDetails" [whoReferringParent]="this.whoReferring" [showErrors]=this.whoReferringErrors [referralSource]=this.referralSource></app-your-details-form>
    <button  mat-raised-button color="primary" matStepperNext (click)="findInvalidControlsWhoReferring()" >Next</button><br><br>
    <small>Section status: {{this.referralForm.get('yourDetails').status === 'VALID' ? 'Complete' : 'Incomplete'}}</small>
    </mat-step>
    <mat-step label={{yourDetailsLabel}} [stepControl]="this.referralForm.get('carers')" id="carerStep">
        
        <div *ngFor="let carer of carers?.controls; let i = index" id="{{ 'carer-form-' + i }}">
          <app-form-carer-details [index]="i" [carerForm]="carer" [firstCarerForm]="carers" [whoReferring]="this.whoReferring" [relationship]="this.referralForm.get('yourDetails.relationship').value" [eContactRelationship]="this.referralForm.get('yourDetails.firstName').value + ' ' + this.referralForm.get('yourDetails.lastName').value" [eContactEmail]="this.referralForm.get('yourDetails.email').value" [eContactTelephone]="this.referralForm.get('yourDetails.telephone').value" [showErrors]=this.carerErrors (deleteCarer)="deleteCarer($event)"></app-form-carer-details>
        </div>
      
      <!-- <button  mat-raised-button matStepperPrevious>Back</button> -->
      <div class="button-row">
        <button   mat-raised-button color="accent"(click)="addCarer()">{{this.addCarerButtonText}}</button> 
        <button  mat-raised-button color="primary" matStepperNext click="stepTo('caredFor')" (click)="findInvalidControlsCarer()">Next</button>
        <div *ngIf="this.referralForm.get('carers').status==='INVALID' && this.referralForm.get('carers').touched" >
          <br>
           <small>Please complete all required fields in this section before continuing.</small> 
        </div>
      </div>
      <br>
      Section status: <span class="status">{{this.referralForm.get('carers').status  === 'VALID' ? 'Complete' : 'Incomplete'}}</span>

    </mat-step>
    <mat-step *ngIf="this.referralSource !== 'ndpeer'" label="Details of People Cared For" id="caredForStep" [stepControl]="this.cardForFormValidControl">
      
      
      
        <div *ngFor="let caredFor of caredFors?.controls; let i = index" id="{{ 'cared-for-form-' + i }}"> 
          <app-form-cared-for [index]="i" [caredForForm]="caredFor" [carerForm]="carers" (deleteCaredFor)="deleteCaredFor($event)"  [showErrors]=this.caredForErrors></app-form-cared-for>
        </div>
        <div *ngIf="formArray.length === 1">
          <h5>What is the relationship between the Carer and this Cared For person?</h5>
          <app-carer-relationship   [carerRelationshipForm]="carerRelationships" [caredForForm]="caredFors" [carerForm]="carers" ></app-carer-relationship>
        </div>

      
      <div class="button-row">
        <button mat-raised-button color="accent" (click)="addCaredFor()">Add another 'Cared For'</button>
        <button  mat-raised-button color="primary" matStepperNext (click)="findInvalidControlsCaredFor()">Next</button>
        <div *ngIf="!this.cardForFormValidControl.valid">
          <br>
           <small>Please complete all required fields in this section before continuing.</small> 
        </div>
      </div>
      <br>
      Section status: <span class="status">{{this.cardForFormValidControl.valid ? 'Complete' : 'Incomplete'}}</span>

    </mat-step>
    <mat-step *ngIf="formArray.length > 1" label="Carer Relationships to Cared For"  [stepControl]="this.referralForm.get('carerRelationships')" > 
      <h5>Please confirm the relationship between each Carer and Each Cared For</h5>

      <app-carer-relationship *ngIf="this.referralSource !== 'ndpeer'" [carerRelationshipForm]="carerRelationships" [caredForForm]="caredFors" [carerForm]="carers" [updateCount]="this.carers.length + this.caredFors.length"></app-carer-relationship>

      <br>
      <div class="button-row">
        <button  mat-raised-button color="primary" matStepperNext>Next</button>
        <div *ngIf="this.referralForm.get('carerRelationships').status==='INVALID'">
          <br>
           <small>Please complete all required fields in this section before continuing.</small> 
        </div>
      </div>
    </mat-step>
    <mat-step label="Confirm">
      <div>
 
          <label><b>Please Check and confirm the details entered</b></label>
            
          
        
        <div class="confirmTable" *ngIf="(this.whoReferring !=='nonCarer')">
          <div *ngFor="let x of carers?.controls; let i = index">
            <div *ngFor="let y of caredFors?.controls; let j = index;" >
              <div   fxLayout="row wrap"   fxLayout.lt-sm="row wrap"   fxLayoutGap="10px"   fxLayoutAlign="flex-start" >
              <div fxLayoutAlign="left center" fxFlex="0 1 calc(40% - 32px)" fxFlex.lt-md="0 1 calc(40% - 32px)" fxFlex.lt-sm="100%">
                {{ x.controls.firstName.value + " " + x.controls.lastName.value}}<button *ngIf="j===0" type="button" (click)="move(1,'carer-form-'+i)" mat-button color="primary">Edit</button>
              </div>
              <div fxLayoutAlign="left center" fxFlex="0 1 calc(20% - 32px)" fxFlex.lt-md="0 1 calc(20% - 32px)" fxFlex.lt-sm="100%">
                cares for
                </div>
              <div fxLayoutAlign="left center" fxFlex="0 1 calc(40% - 32px)" fxFlex.lt-md="0 1 calc(40% - 32px)" fxFlex.lt-sm="100%">
                {{ y.controls.firstName.value + " " + y.controls.lastName.value}}<button *ngIf="i===0" type="button" (click)="move(2,'cared-for-form-'+j)" mat-button color="primary">Edit</button> ({{getRelationship(x.controls.guid.value,y.controls.guid.value)}})
              </div>
              
              </div>
              <mat-divider></mat-divider>
            </div>
            
          </div>
        </div>
      </div>
      

      <div *ngFor="let carer of carers?.controls; let i = index">
        <app-carer-consent [index]="i" [carerForm]="carer" [whoReferring]="this.whoReferring" [relationship]="this.referralForm.get('yourDetails.relationship').value" [showErrors]=this.consentErrors></app-carer-consent>
      </div>
      
      <br>
      <div *ngIf="this.referralForm.invalid" >
        <br>
         <small>Please complete all required fields before continuing.</small> 
      </div>
    
      <br>
      <button [disabled]="this.saving" type="submit" mat-raised-button color="accent" (click)="saveReferral()" >{{this.saveButtonText}}</button>
      <br><br>
      <div *ngIf="this.submitError" class="glow" >
        {{ this.submitError }}
        </div>
      <br><span *ngIf="this.consentErrors" class="status">Please complete all required details (highlighted in red).</span><br>



    </mat-step>
  </mat-horizontal-stepper>
  <!-- <button  mat-raised-button color="basic" (click)="getFormValidationErrors()">Check Validation</button> -->


</form>
<div *ngIf="this.completedRef">
<app-form-complete [id]="this.completedRef" [refUrl]="this.referralSource"></app-form-complete>
</div>


  
  
  
  