export class GP {
    constructor(
        public orgCode: string,
        public name: string,
        public addressLine1: string,
        public addressLine2: string,
        public addressLine3: string,
        public addressLine4: string,
        public postCode: string,
        public manualEntry: boolean
        ) {}
  }

  export interface IGPResponse {
    total: number;
    results: GP[];
  }

  export class School {
    constructor(
        public urn: number,
        public establishmenName: string,
        public addressLine1: string,
        public addressLine2: string,
        public addressLine3: string,
        public town: string,
        public county: string,
        public postCode: string,
        public manualEntry: boolean


        ) {}
  }

  export interface ISchoolResponse {
    total: number;
    results: School[];
  }
  export interface IEVentDetails {
    eventName: string;
    eventLocation: string;
    eventStartDate: Date;
    eventEndDate: Date;
    eventDescription: string;
    alreadyResponded: boolean;
    atCapacity: boolean;
  }