import { Component, OnInit, Input, Output, ChangeDetectionStrategy, EventEmitter, OnDestroy } from '@angular/core'
import { trigger, transition, animate, style } from '@angular/animations'
import { FormGroup, Validators } from '@angular/forms'
import {AppSettings} from '../AppSetting';
import { stringify } from 'querystring';
import { ApiService } from '../api.service';
import { ChangeDetectorRef } from '@angular/core';
import { Subject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';


@Component({
  selector: 'app-carer-consent',
  templateUrl: './carer-consent.component.html',
  styleUrls: ['./carer-consent.component.scss']
})
export class CarerConsentComponent implements OnInit {

  @Input() carerForm: FormGroup
  @Input() index: number
  @Input() whoReferring: string
  @Input() relationship: number
  @Input() showErrors: boolean;

  constructor(private chRef: ChangeDetectorRef) { }

  age: number;
  fullName: string;




  ngOnInit(): void {
    this.fullName = this.carerForm.get('firstName').value + "" + this.carerForm.get('lastName').value;

    //Add validation
    //this.carerForm.get('consentStoreData').setValidators([Validators.required]);

    
  }

  public CalculateAge(): void {
    var birthDate: string;
    birthDate = this.carerForm.get('dateOfBirth').value;
    if (this.carerForm.get('dateOfBirth')) {
    var timeDiff = Math.abs(Date.now() - new Date(birthDate).getTime());
    this.age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
    console.log(this.age)
    if (this.age < 18 )
    {
     
    }
    else 
    {
     
    }
    }
    this.chRef.detectChanges();
  
    }

}
