import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { IEVentDetails} from '../importdata.class'
import {Title} from "@angular/platform-browser";


@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {



    eventForm = new FormGroup({
    InviteGuid: new FormControl(''),
    Response: new FormControl(''),
    NameOfParticipants: new FormControl(''),
    ContactNumber: new FormControl(''),
    EmergencyContactName: new FormControl(''),
    EmergencyContactRelationship: new FormControl(''),
    EmergencyContactNumber: new FormControl(''),
    HealthMedicalNeeds: new FormControl(''),
    DietaryRequirements: new FormControl(''),
    OtherInfo: new FormControl(''),
    PhotoVideoConsent: new FormControl(''),
    CompletedBy: new FormControl('')
  });

  eventDeclineForm = new FormGroup({
    InviteGuid: new FormControl(''),
    Response: new FormControl(''),
    
  });

  inviteGuid: string = this.route.snapshot.queryParamMap.get('invite');

  eventData: IEVentDetails;
  startDate: Date;
  endDate: Date;
  completedRef: number;
  declinedRef: number;
  saving: boolean;
  saveButtonText: string;
  atCapacity: boolean;
  isLoaded: boolean;
  alreadyResponded: boolean;

  constructor(private titleService:Title, private route: ActivatedRoute, private apiService: ApiService, private router: Router) 
  {this.titleService.setTitle("Suffolk Family Carers Events") }

  ngOnInit(): void {
    this.isLoaded = false;
    this.saveButtonText = 'Submit response';
    this.apiService.getEventDetails(this.inviteGuid)
      .subscribe((data) => {
        this.eventData = data
        this.startDate = new Date(data.eventStartDate)
        this.endDate = new Date(data.eventEndDate)
        this.atCapacity = data.atCapacity;
        this.alreadyResponded = data.alreadyResponded;
        this.isLoaded = true;
        console.log(this.inviteGuid)
      },
      error => { console.log(error);
        this.saveButtonText = 'Submit Registration';
        //this.submitError = "Sorry, there was a problem submitting your details. Please try again."
        //this.dialog.open(DialogComponentComponent);
       
      });
  }

  submitResponse() {
    this.eventForm.controls['InviteGuid'].setValue(this.inviteGuid);
    if (this.eventForm.get('PhotoVideoConsent').value !== true)
    {
      this.eventForm.controls['PhotoVideoConsent'].setValue(false);
    }

    console.log('Response saving')
    this.saving = true;

      console.log(this.eventForm.getRawValue())

      
    this.saveButtonText = "Submitting - Please wait";
      this.apiService.submitEventConsent(this.eventForm.getRawValue())
      .subscribe((data) => {
        this.completedRef = data
        
        
      },
      error => { console.log(error);
        this.saveButtonText = 'Submit response';
        this.saving = false;

       
      });
      this.waitForElement('completeResult');      
    }

  
  

  onToggleChange(value: boolean) {
    if (value === false)
    {  
      this.eventDeclineForm.controls['InviteGuid'].setValue(this.inviteGuid);
      this.eventDeclineForm.controls['Response'].setValue(this.eventForm.get('Response').value);
      console.log('Decline response saving');

      this.apiService.submitEventDecline(this.eventDeclineForm.getRawValue())
      .subscribe((data) => {
        this.declinedRef = data
        
        
      },
      error => { console.log(error);
        this.declinedRef = -1;
        console.log(this.declinedRef)
      });
      this.waitForElement('declineResult');


    }
    else
    {
      this.waitForElement('formStart');
    }
    
    console.log(value);
}

modeNewEntry = false;
  @ViewChild('textCreate', { static: false }) textCreateComponent: ElementRef;

  showComponent() {
    this.modeNewEntry = true;
      this.waitForElement('textcreate');
  }


  //New scoll code starts here
  waitForElement(selector) {
    let element = document.getElementById(selector);
    if (element) {
      scroll(selector);
      return;
    }
    let observer = new MutationObserver(mutations => {
      mutations.forEach(function(mutation) {
        let nodes = Array.from(mutation.addedNodes);
        for (var node of nodes) {
          if (node.contains(document.getElementById(selector))) {
            scroll(selector);
            observer.disconnect();
            return;
          }
        }
      });
    });
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true
    });
  }
  



}

function scroll(selector) {
  document.getElementById(selector).scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  });
}