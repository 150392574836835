<div  *ngFor="let carerRelationship of carerRelationshipForm.controls;  let i = index" >
    <div  [formGroup]="carerRelationship"  fxLayout="row wrap"   fxLayout.lt-sm="column"   fxLayoutGap="32px"   fxLayoutAlign="flex-start">
        <div  fxLayoutAlign="left center" fxFlex="0 1 calc(25% - 32px)" fxFlex.lt-md="0 1 calc(25% - 32px)" fxFlex.lt-sm="50%">
            {{getCaredForName(carerRelationship.controls.cfGuid.value)}}
        </div>
        <div  fxLayoutAlign="left center" fxFlex="0 1 calc(25% - 32px)" fxFlex.lt-md="0 1 calc(25% - 32px)" fxFlex.lt-sm="50%">
            relationship to
            </div>
        <div  fxLayoutAlign="left center" fxFlex="0 1 calc(25% - 32px)" fxFlex.lt-md="0 1 calc(25% - 32px)" fxFlex.lt-sm="50%">
       <div >
        {{getCarerName(carerRelationship.controls.carerGuid.value)}}
       </div>
    </div>


    <div  fxLayoutAlign="left center" fxFlex="0 1 calc(25% - 32px)" fxFlex.lt-md="0 1 calc(25% - 32px)" fxFlex.lt-sm="50%">
        <mat-form-field>
            
            <mat-select #relationshipSelect matInput id="relationshipSelect" placeholder="Relationship to carer" formControlName="relationship">
                <mat-option *ngFor="let item of relationshipList" [value]="item.value">
                    {{item.viewValue}}
                  </mat-option>
            </mat-select>
          </mat-form-field>   
    </div>
    

    </div>
    <mat-divider></mat-divider>
</div>
<br>


     
        
        
