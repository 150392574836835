import { FormArray, FormControl, Validators, FormGroup } from '@angular/forms'
import { Referral } from './referral.model'


export class ReferralForm {
    referredBy = new FormControl();
    referralUrl = new FormControl();
    name = new FormControl()
    carers = new FormArray([])
    caredFors = new FormArray([])
    carerRelationships = new FormArray([])
    yourDetails = new FormGroup
    ({
            

        firstName: new FormControl(),
        lastName: new FormControl(),
        relationship: new FormControl(),
        orgName: new FormControl(),
        orgRole: new FormControl(),
        email: new FormControl(),
        telephone: new FormControl(),
        postCode: new FormControl(),
        addressLine1: new FormControl(),
        addressLine2: new FormControl(),
        addressLine3: new FormControl(),
        city:new FormControl(),
        county: new FormControl(),
        comments: new FormControl(),
        howDidYouHear: new FormControl(),
        howDidYouHearOther: new FormControl(),
        consentToSfcHoldingInfo: new FormControl(),
        consentAllIndividuals: new FormControl(),
        advisorContact: new FormControl(),
        preferredContactMethod: new FormControl(),
        subscribeProNewsletter: new FormControl(),

        });
        
    

    constructor(referral: Referral) {
        if (referral.referredBy)
        {
            this.referredBy.setValue(referral.referredBy)
            this.referredBy.setValidators([Validators.required])
        }
        if (referral.name) {
            this.name.setValue(referral.name)
        }

        if (referral.carers) {
            this.carers.setValue(referral.carers)
        }

        if (referral.caredFors) {
            this.caredFors.setValue(referral.caredFors)
        }
        if (referral.carerRelationships) {
            this.carerRelationships.setValue(referral.carerRelationships)
        }
        // if (referral.yourDetails) {
        //     this.yourDetails.setValue(referral.yourDetails)
            
        // }
       

       
        
    }
}