import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'
import { FormGroup, FormBuilder, FormArray, Validators, ValidationErrors } from '@angular/forms'
import { ReferralForm, Referral } from './_models'
import { CarerForm, Carer } from '../form-carer-details'
import { CaredForForm, CaredFor } from '../form-cared-for'
import { CarerRelationshipForm, CarerRelationship } from '../carer-relationship'
import { v4 as uuidv4 } from 'uuid';

function atLeastOne(...fields: string[]) {
  return (fg: FormGroup): ValidationErrors | null => {
    return fields.some(fieldName => {
      const field = fg.get(fieldName).value;
      if (typeof field === 'number') return field && field >= 0 ? true : false;
      if (typeof field === 'string') return field && field.length > 0 ? true : false;
    })
      ? null
      : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
  };
}

//import { format } from 'path'
import { ConfigurableFocusTrap } from '@angular/cdk/a11y'

@Injectable()
export class ReferralFormService {
  private referralForm: BehaviorSubject<
    FormGroup | undefined
  > = new BehaviorSubject(this.fb.group(new ReferralForm(new Referral(''))))
  referralForm$: Observable<FormGroup> = this.referralForm.asObservable()

  constructor(private fb: FormBuilder) {}

  addSingleCarer()
  {  const currentReferral = this.referralForm.getValue()

    let newCarerGuid: string = uuidv4();
    this.addCarer(newCarerGuid);

    const caredForForm = currentReferral.get('caredFors') as FormArray

    for (let  caredFor  of caredForForm.controls) {
      
      this.addCarerRelationship(newCarerGuid,caredFor.get('guid').value)
   }

  }

  addSingleCaredFor()
  {
    const currentReferral = this.referralForm.getValue()

    let newCaredForGuid: string = uuidv4();
    this.addCaredFor(newCaredForGuid);

    const carerForm = currentReferral.get('carers') as FormArray

    for (let  carer  of carerForm.controls) {
      
      this.addCarerRelationship(carer.get('guid').value,newCaredForGuid)
   }

  }

  addCarerRelationship(cGuid: string, cfGuid: string) {
    const currentReferral = this.referralForm.getValue()
    const currentForm = currentReferral.get('carerRelationships') as FormArray

    currentForm.push(
      this.fb.group(
        new CarerRelationshipForm(new CarerRelationship(cGuid, cfGuid))
      )
    )

    this.referralForm.next(currentReferral)
  }

  deleteCarerRelationship(carerGuid?: string, cfGuid?: string) {
    const currentReferral = this.referralForm.getValue()
    const currentForm = currentReferral.get('carerRelationships') as FormArray

    let arraylen = currentForm.length;

    console.log('Start array length: ' + arraylen)
    if (carerGuid !== null)
    {

      console.log('Deleting based on Carer GUID');

    

      var c = 0;
      for (let val of currentForm.controls)
      {
      
      var thisItem = val as FormGroup;
      
      if (thisItem.controls.carerGuid.value === carerGuid)
      {
        
        console.log(`Found at location: ` + c)
      }
        c++;
      }

      // test code starts here:

      // currentForm.controls.forEach( (myObject, index) => {
      //   var thisItem = myObject as FormGroup;
      //   if(thisItem.controls.carerGuid.value === carerGuid) {
      //     currentForm.removeAt(index);
      //     console.log(`Removed at index: ` + index);
      //   }
      // });


      console.log('Start remove here -----');
      //Delete based on Carer GUID

      

      for (var i = currentForm.length - 1; i >= 0; i--) {
        var aItem = currentForm.controls[i] as FormGroup;
        if (aItem.controls.carerGuid.value === carerGuid)
      {
        
        console.log(`Delete this `+ carerGuid)
        console.log(`Delete at location: ` + i)
        currentForm.removeAt(i)
        
      }
      }



      // var y = 0;
      // for (let x of currentForm.controls)
      // {
      //   console.log(`Current length: ` + currentForm.length.toString());

      // console.log(`Loop: ` + y);
      // var thisItem = x as FormGroup;
      
      // if (thisItem.controls.carerGuid.value === carerGuid)
      // {
        
        
        
      //   console.log(`Delete this `+ carerGuid)
      //   console.log(`Delete at location: ` + y)
      //   currentForm.removeAt(y)
        
      // }
      // else{
      //   y++;
      // }
     

      // }
    }
    else if (cfGuid !== null)
    {
      console.log('Deleting based on CF Guid')

      var c = 0;
      for (let x of currentForm.controls)
      {
      
      var thisItem = x as FormGroup;
      
      if (thisItem.controls.cfGuid.value === cfGuid)
      {
        
        console.log(`Found at location: ` + c)
      }
      c++;

      }
      for (var i = currentForm.length - 1; i >= 0; i--) {
        var aItem = currentForm.controls[i] as FormGroup;
        if (aItem.controls.cfGuid.value === cfGuid)
      {
        
        console.log(`Delete this `+ cfGuid)
        console.log(`Delete at location: ` + i)
        currentForm.removeAt(i)
        
      }
      }
      // currentForm.controls.forEach( (myObject, index) => {
      //   var thisItem = myObject as FormGroup;
      //   if(thisItem.controls.cfGuid.value === cfGuid) {
      //     currentForm.removeAt(index);
      //     console.log(`Removed at index: ` + index);
      //   }
      // });
      // var y = 0;
      // for (let x of currentForm.controls)
      // {
      
      // var thisItem = x as FormGroup;
      
      // if (thisItem.controls.cfGuid.value === cfGuid)
      // {
        
      //   console.log(`Delete this `+ cfGuid)
      //   console.log(`Deleting at location ` + y)
      //   currentForm.removeAt(y)
        
      // }
      // else{
      //   y++;
      // }
      

      // }

    }

    this.referralForm.next(currentReferral)
  }

  addCarerCaredFor()
  {
    let newCarerGuid: string = uuidv4();
    let newCaredForGuid: string = uuidv4();
    this.addCarer(newCarerGuid);
    this.addCaredFor(newCaredForGuid);
    this.addCarerRelationship(newCarerGuid, newCaredForGuid)

  }

  

  addCarer(carerGuid?: string) {
    const currentReferral = this.referralForm.getValue()
    const currentCarers = currentReferral.get('carers') as FormArray
    currentCarers.push(
      this.fb.group(
        new CarerForm(new Carer(carerGuid))
      )
    )
    

    this.referralForm.next(currentReferral)
  }

  deleteCarer(i: number) {
    const currentReferral = this.referralForm.getValue()
    var currentCarers = currentReferral.get('carers') as FormArray
    var item = currentCarers.at(i) as FormGroup;
    var guid = item.controls.guid.value;

    this.deleteCarerRelationship(guid,null);
    
    currentCarers.removeAt(i)

    this.referralForm.next(currentReferral)
  }
  addCaredFor(caredForGuid?: string) {
    const currentReferral = this.referralForm.getValue()
    const currentCarerdFors = currentReferral.get('caredFors') as FormArray

    currentCarerdFors.push(
      this.fb.group(
        new CaredForForm(new CaredFor(caredForGuid)), { validator: atLeastOne('dateOfBirth','ageRange') },
      )
    )

    this.referralForm.next(currentReferral)
  }

  deleteCaredFor(i: number) {
    const currentReferral = this.referralForm.getValue()
    const currentCarerdFor = currentReferral.get('caredFors') as FormArray
    var item = currentCarerdFor.at(i) as FormGroup;
    var guid = item.controls.guid.value;
    this.deleteCarerRelationship(null, guid);


    currentCarerdFor.removeAt(i)

    this.referralForm.next(currentReferral)
  }

 
  
}