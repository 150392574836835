import { GP, School } from '../../importdata.class'

export class Carer {
    guid: string
    firstName: string
    lastName: string
    title: number
    dateOfBirth: string
    age: number
    gender: number
    email: string
    telephone: string
    ethnicity: number
    postCode: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    county: string;
    caringRoleDetails: string;
    parentFirstName: string;
    parentLastName: string;
    parentEmail: string;
    parentTelephone: string;
    parentPostCode: string;
    parentAddressLine1: string;
    parentAddressLine2: string;
    parentAddressLine3: string;
    parentCity: string;
    parentCounty: string;
    reasonForReferral: number;
    eContact1Email: string;
    eContact2Email: string;
    eContact1Telephone: string;
    eContact2Telephone: string;
    eContact1Relationship: string;
    eContact2Relationship: string;
    educationStatus: number;
    mainCarer: boolean;
    hoursCaring: number;
    howDidYouHear: number;
    howDidYouHearOther: string;
    nhsNumber: string;
    
    armedForcesShow: boolean;
    armedForcesMember: number;
    armedForcesBranch: number;
    armedForcesStatus: number;
    
    consentStoreData: boolean;
    consentShareData: boolean;
    consentYcStatutoryAssessment: boolean;
    consentContactTelephone: boolean;
    consentContactEmail: boolean;
    consentContactSms: boolean;
    consentContactPost: boolean;
    subscribeNewsletter: boolean;
    hasTabletOrComputer: boolean;
    requestContact: boolean;
    gpCode: GP;
    schoolCode: School;

    constructor(guid?: string) {
        this.guid = guid
        
    }
}