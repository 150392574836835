export const AppSettings: any = {

    Title: [
     { "value": 1, "viewValue": "Mr" },
     { "value": 2, "viewValue": "Mrs" },
     { "value": 3, "viewValue": "Master" },
     { "value": 4, "viewValue": "Miss" },
     { "value": 5, "viewValue": "Ms" },
     { "value": 6, "viewValue": "Dr" },
     { "value": 7, "viewValue": "Rev" },
     { "value": 8, "viewValue": "Prof" }
    ],

    Gender: [

        { "value": 10, "viewValue": "Female" },
        { "value": 9, "viewValue": "Male" },
        { "value": 11, "viewValue": "Other" },
        { "value": 564, "viewValue": "Prefer not to say" }

       ],

    ReasonForReferral: [
        { "value": 2263, "viewValue": "Community Support" },
        { "value": 2257, "viewValue": "Condition Specific Information" },
        { "value": 2261, "viewValue": "Emotional Support" },
        { "value": 2260, "viewValue": "Information & Guidance" },
        { "value": 2262, "viewValue": "Loneliness & Isolation" },
        { "value": 2258, "viewValue": "Personal Development / Life Skills" },
        { "value": 2256, "viewValue": "Respite Breaks/Activities" },
        { "value": 2259, "viewValue": "Training & Education" },
        { "value": 2255, "viewValue": "Wellbeing/Emotional Support" }
    ],

    Relationships: [
        { "value": 2328, "viewValue": "Parent" },
        { "value": 2329, "viewValue": "Guardian" },
        { "value": 2330, "viewValue": "Professional" },
        { "value": 2358, "viewValue": "Education Provider" },
        { "value": 2331, "viewValue": "Other" },
    ],

    CarerRelationshipList: [
        { "value": 92, "viewValue": "Parent" },
        { "value": 93, "viewValue": "Child" },
        { "value": 568, "viewValue": "Child (18+)" },
        { "value": 616, "viewValue": "Spouse" },
        { "value": 91, "viewValue": "Partner" },
        { "value": 180, "viewValue": "Sibling" },
        { "value": 181, "viewValue": "Other Relative" },
        { "value": 240, "viewValue": "Guardian" },
        { "value": 2246, "viewValue": "Foster Carer" },
        { "value": 178, "viewValue": "Friend" },
        { "value": 179, "viewValue": "Neighbour" },
        { "value": 94, "viewValue": "Other" }

        

        
    ],

    HowDidYouHear: [
        { "value": 2433, "viewValue":  "Suffolk County Council" },
        { "value": 2434, "viewValue":  "Education Setting" },
        { "value": 2435, "viewValue":  "Mental Health Organisation" },
        { "value": 2436, "viewValue":  "Hospital Setting" },
        { "value": 2437, "viewValue":  "GP " },
        { "value": 2438, "viewValue":  "Pharmacy" },
        { "value": 2439, "viewValue":  "Word of Mouth" },
        { "value": 2440, "viewValue":  "Suffolk Family Carers Staff" },
        { "value": 2441, "viewValue":  "Leaflet/Poster" },
        { "value": 2442, "viewValue":  "Social Media/Internet Search" },
        { "value": 2443, "viewValue":  "Turning Point" },
        { "value": 2444, "viewValue":  "Other Charity Organisation" },
        { "value": 2445, "viewValue":  "Media (TV/Radio/Press)" },
        { "value": 2446, "viewValue":  "Emergency Services" },
        { "value": 2447, "viewValue":  "Employer" },
        { "value": 2448, "viewValue":  "Military" },
        { "value": 2449, "viewValue":  "Other" }
    ],
    ContactMethods: [
        { "value": 2359, "viewValue": "Telephone" },
        { "value": 2360, "viewValue": "E-mail" },
        { "value": 2361, "viewValue": "Post" }
    ],
    Ethnicity: [
        { "value": 67, "viewValue": "Asian - Any other Asian background" },
        { "value": 66, "viewValue": "Asian - Bangladeshi" },
        { "value": 816, "viewValue": "Asian - British" },
        { "value": 64, "viewValue": "Asian - Indian" },
        { "value": 65, "viewValue": "Asian - Pakistani" },
        { "value": 69, "viewValue": "Black - African" },
        { "value": 70, "viewValue": "Black - Any other Black background" },
        { "value": 815, "viewValue": "Black - British" },
        { "value": 68, "viewValue": "Black - Caribbean" },
        { "value": 238, "viewValue": "Gypsy/Traveller" },
        { "value": 63, "viewValue": "Mixed - Any other mixed background" },
        { "value": 62, "viewValue": "Mixed - White and Asian" },
        { "value": 61, "viewValue": "Mixed - White and Black African" },
        { "value": 60, "viewValue": "Mixed - White and Black Caribbean" },
        { "value": 72, "viewValue": "Other - Any other ethnic group" },
        { "value": 71, "viewValue": "Other - Chinese" },
        { "value": 59, "viewValue": "White - Any other White background" },
        { "value": 16, "viewValue": "White - British" },
        { "value": 17, "viewValue": "White - Irish" },
        { "value": 567, "viewValue": "Prefer not to say" },
        { "value": 9139, "viewValue": "Unknown" }

    ],
    EducationStatus: [
        { "value": 85, "viewValue": "Student - Full time" },
        { "value": 792, "viewValue": "Student - Part time" },
        { "value": 2454, "viewValue": "Apprenticeship" },
        { "value": 83, "viewValue": "Full time employment" },
        { "value": 132, "viewValue": "Self Employed" },
        { "value": 84, "viewValue": "Part time employment" },
        { "value": 619, "viewValue": "Not in Edu, Emp or Training" },
        { "value": 620, "viewValue": "Home educated" },
        { "value": 604, "viewValue": "Pre School" },
        { "value": 573, "viewValue": "Unable to work" },
        { "value": 2455, "viewValue": "Other" },
        { "value": 2456, "viewValue": "Prefer not to say" }


    ],
    ArmedForcesMember: [
        { "value": 2457, "viewValue": "Armed Forces" },
        { "value": 2458, "viewValue": "Emergency Services" },
        { "value": 2459, "viewValue": "Other" }
    ],
    ArmedForcesBranch: [
        { "value": 2265, "viewValue": "Air Force" },
        { "value": 2266, "viewValue": "Army" },
        { "value": 2267, "viewValue": "Marines" },
        { "value": 2268, "viewValue": "Navy" },
        { "value": 2269, "viewValue": "Non British Forces" },
        { "value": 2270, "viewValue": "Not Specified" }
    ],
    ArmedForcesStatus: [
        { "value": 2314, "viewValue": "Family Member of Regular Serving" },
        { "value": 2315, "viewValue": "Family Member of Reservist" },
        { "value": 2316, "viewValue": "Family Member of Veteran" },
        { "value": 2317, "viewValue": "Not Specified" },
        { "value": 2311, "viewValue": "Regular Serving" },
        { "value": 2312, "viewValue": "Reservist" },
        { "value": 2313, "viewValue": "Veteran" }
    ],
    EmergencyServicesBranch: [
        { "value": 2272, "viewValue": "Ambulance    " },
        { "value": 2273, "viewValue": "Fire" },
        { "value": 2274, "viewValue": "Police" },
        { "value": 2275, "viewValue": "Other" }
    ],
    EmergencyServicesStatus: [
        { "value": 2318, "viewValue": "Current Member" },
        { "value": 2319, "viewValue": "Ex Member" },
        { "value": 2320, "viewValue": "Family Of Current Member" },
        { "value": 2321, "viewValue": "Family Of Ex Member" },
        { "value": 2322, "viewValue": "Not Specified" }
    ],
    AgeRanges: [
        { "value": 1753, "viewValue": "0-4" },
        { "value": 1716, "viewValue": "5-9" },
        { "value": 1717, "viewValue": "10-15" },
        { "value": 1718, "viewValue": "16-25" },
        { "value": 1719, "viewValue": "26-50" },
        { "value": 1720, "viewValue": "51-99" }
    ],
    MedicalConditions: [
        { "value": 1, "viewValue": "AD/HD (attention deficit/hyperactivity disorder)" },
        { "value": 2, "viewValue": "Arthritis" },
        { "value": 3, "viewValue": "Autism spectrum disorder" },
        { "value": 4, "viewValue": "Bipolar" },
        { "value": 5, "viewValue": "Cancer (all, including leukaemia)" },
        { "value": 6, "viewValue": "Dementia (all types)" },
        { "value": 7, "viewValue": "Depression and Anxiety" },
        { "value": 8, "viewValue": "Diabetes (both types)" },
        { "value": 9, "viewValue": "Dual Diagnosis (Learning Disability/ other)" },
        { "value": 10, "viewValue": "Dual Diagnosis (Mental Health/other)" },
        { "value": 11, "viewValue": "Eating Disorders" },
        { "value": 12, "viewValue": "Epilepsy" },
        { "value": 13, "viewValue": "Frail Elderly    " },
        { "value": 14, "viewValue": "Heart Disease" },
        { "value": 15, "viewValue": "Learning Disability" },
        { "value": 16, "viewValue": "Multiple Sclerosis MS" },
        { "value": 17, "viewValue": "Neurological Other" },
        { "value": 18, "viewValue": "OCD Obsessive Compulsive Disorder and phobias" },
        { "value": 19, "viewValue": "Osteoporosis" },
        { "value": 20, "viewValue": "Other – physical condition" },
        { "value": 21, "viewValue": "Other – mental health" },
        { "value": 22, "viewValue": "Parkinson’s disease" },
        { "value": 23, "viewValue": "Personality disorder" },
        { "value": 24, "viewValue": "Physical Disability (mobility, spinal)" },
        { "value": 25, "viewValue": "Post-traumatic stress disorder PTSD" },
        { "value": 26, "viewValue": "Psychosis" },
        { "value": 27, "viewValue": "Schizophrenia other related conditions" },
        { "value": 28, "viewValue": "Sensory Disability (hearing, sight loss)" },
        { "value": 29, "viewValue": "Stroke" },
        { "value": 30, "viewValue": "Substance misuse (alcohol)" },
        { "value": 31, "viewValue": "Substance misuse (drug problems)" },
        { "value": 32, "viewValue": "Respiratory" },
        { "value": 33, "viewValue": "COPD" },
        { "value": 34, "viewValue": "UTI" },
        { "value": 37, "viewValue": "Kidney conditions" }
    ],
    HoursCaring: [
        { "value": 642, "viewValue": "0-19" },
        { "value": 643, "viewValue": "20-49" },
        { "value": 644, "viewValue": "50 or more" }
    ],
    

    CaredForLocations: [
        { "value": 2450, "viewValue":  "Independently" },
        { "value": 2451, "viewValue":  "In a care provision" },
        { "value": 2452, "viewValue":  "With you" },
        { "value": 2453, "viewValue":  "Prefer not to say" }
    ],
    
 }