import { Component, OnInit, Input, Output, ChangeDetectionStrategy, EventEmitter, OnDestroy, NgZone, SimpleChanges } from '@angular/core'
import { trigger, transition, animate, style } from '@angular/animations'
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms'
import {AppSettings} from '../AppSetting';
import { stringify } from 'querystring';
import { ApiService } from '../api.service';
import { ChangeDetectorRef } from '@angular/core';
import { Subject, EMPTY, Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap, finalize } from 'rxjs/operators';
import { GP, School } from '../importdata.class'
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as moment from 'moment';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { E, T, Y } from '@angular/cdk/keycodes';
import { ValidationAlphabetLocale } from '@rxweb/reactive-form-validators';
// import { Console } from 'console';
// import { timingSafeEqual } from 'crypto';



interface MfLookup {
  value: string;
  viewValue: string;
}




@Component({
  selector: 'app-form-carer-details',
  templateUrl: './form-carer-details.component.html',
  styleUrls: ['./form-carer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  // providers: [ApiService],

  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('400ms ease-in', style({transform: 'translateY(0%)'}))
      ]),
      transition(':leave', [
        animate('400ms ease-in', style({transform: 'translateY(-100%)'}))
      ])
    ])
  ]


})
export class FormCarerDetailsComponent implements OnInit, OnDestroy {

  @Input() carerForm: FormGroup
  @Input() index: number
  @Input() whoReferring: string
  @Input() relationship: number
  @Input() eContactRelationship: string
  @Input() eContactEmail: string
  @Input() eContactTelephone: string
  @Output() deleteCarer: EventEmitter<number> = new EventEmitter()
  @Input() showErrors: boolean;
  @Input() firstCarerForm: FormGroup;

  titles: MfLookup[] = AppSettings.Title; 
  genders: MfLookup[] = AppSettings.Gender;
  ethnicities: MfLookup[] = AppSettings.Ethnicity
  referralReasons: MfLookup[] = AppSettings.ReasonForReferral
  educationStatusList: MfLookup[] = AppSettings.EducationStatus
  armedForcesMemberList: MfLookup[] = AppSettings.ArmedForcesMember
  armedForcesBranchList: MfLookup[] = AppSettings.ArmedForcesBranch
  armedForcesStatusList: MfLookup[] = AppSettings.ArmedForcesStatus
  emergencyServicesBranchList: MfLookup[] = AppSettings.EmergencyServicesBranch
  emergencyServicesStatusList: MfLookup[] = AppSettings.EmergencyServicesStatus
  hoursCaring: MfLookup [] = AppSettings.HoursCaring;
  howDidYouHearList: MfLookup[] = AppSettings.HowDidYouHear
  debug: boolean;
  parentEContactCheckbox: boolean;

  //Setup debounce
  term$ = new Subject<string>();
  private searchSubscription: Subscription;

  age: number;
  public birthDate: string;
  carerAddressError: string;
  parentAddressError: string;
  carerAddresses: any;
  parentAddresses: any;
  schoolAddresses: any;
  gpAddresses: any;
  gpResults: GP[] = [];
  schoolResults: School[] = [];
  isLoading = false;
  isSchoolLoading = false;
  //filteredGpResults: Observable<GP[]>;
  gpManual: boolean = false;
  schoolManual: boolean = false;
  gpCtrl = new FormControl();
  schoolCtrl = new FormControl();
  //armedForcesShow = new FormControl([Validators.required]);
  dobControl = new FormControl();
  hasTabletOrComputerLabel: string;
  parentAddressChecked: boolean;
  suggestDate: string;
  sameCarerAddressCheck: boolean;
  addButtonText: string;
  reasonLabelText: string;
  carerOrPersonText: string;
  noEmail: boolean;

  constructor(private apiService: ApiService, private chRef: ChangeDetectorRef, private ngZone: NgZone) {}
  
  

  ngOnInit() {
    this.sameCarerAddressCheck = false;
    this.debug = false;
    this.age = 50;
    this.carerOrPersonText = "carer";

    
    console.log(this.whoReferring + ' ' + this.index)
    
    if (this.whoReferring === 'myself' && this.index === 0)
    {
      this.hasTabletOrComputerLabel = 'Do you have a computer, tablet or mobile and reguarly access the internet?';
    }
    else{
      this.hasTabletOrComputerLabel = 'Does this carer have a computer, tablet or mobile and reguarly accesses the internet?';
    }

    if (this.whoReferring === "nonCarer")
    {
        this.addButtonText = "Person";
        this.carerOrPersonText = "person";
    }
    else
    {
      this.addButtonText = "Carer"
    }


    //GP
    this.gpCtrl.valueChanges.pipe(
      debounceTime(500),
      tap(() => this.isLoading = true),
      switchMap(value => this.apiService.searchGP({name: value}, 1)
      .pipe(finalize(() => this.isLoading = false), 
      )
    )
    )
    .subscribe(gps => this.gpResults = gps.results);

        //School
        this.schoolCtrl.valueChanges.pipe(
          debounceTime(500),
          tap(() => this.isSchoolLoading = true),
          switchMap(value => this.apiService.searchSchool({name: value}, 1)
          .pipe(finalize(() => this.isSchoolLoading = false), 
          )
        )
        )
        .subscribe(s => this.schoolResults = s.results);
     
    
      
    
    
    // .subscribe(

    //   term => {
    //     if(term!='') {
    //       this.apiService.searchGP(term).subscribe(
    //         data=>{
    //           this.gpResults = data as any[];
    //         }
    //       )
    //     }
    //   }
    // )
    this.searchSubscription = this.term$.pipe(
      debounceTime(2000),
      distinctUntilChanged(),
      switchMap(term => {
        console.log('text changed')
        if (this.dobControl.valid)
        {
          this.CalculateAge();
        }
        return EMPTY;
      })
    ).subscribe();

  }

  ngOnChanges(changes: SimpleChanges){
    for (const propName in changes){
      if(changes.hasOwnProperty(propName)) {
        let change = changes[propName];
        switch (propName){
          case 'whoReferring': {
            if (this.dobControl.valid)
            {
              this.CalculateAge();
            }
          }
        }
      }
    }
  }

  delete() {
    this.deleteCarer.emit(this.index)
  }

  addressLookup(addressType: string, postCode: string){
    if (addressType === "carer")
    {
      this.carerAddresses = null;
      this.carerAddressError = null;

    }
    else if (addressType === "parent")
      {
      this.parentAddresses = null;
      this.parentAddressError = null;
      }
    var addressVar;
    var re = / ,/g
    //var postcode: string;
    //postcode = this.carerForm.get('postCode').value;
    console.log('Address lookup starting with ' + postCode)
    this.apiService.getAddress(postCode).subscribe((data)=>{ 
    addressVar = data['addresses'];
    if (addressType === "carer")
    {
      console.log('Carer address lookup')
      this.carerAddresses = addressVar
  

    }
      else if (addressType === "parent")
      {
        console.log('Parent address lookup')
        this.parentAddresses = addressVar
      }
      else if (addressType === "school")
      {
        console.log('School address lookup')
        this.schoolAddresses = addressVar
      }
    this.chRef.detectChanges();
    },
    error => { console.log(error);
      if (addressType === "carer")
    {
      this.carerAddressError = "Sorry, that postcode wasn't found. Please check or enter the address manually."

  

    }
      else if (addressType === "parent")
      {
        this.parentAddressError = "Sorry, that postcode wasn't found. Please check or enter the address manually."

      }
    });
    

  }

  schoolAddressLookup(action: string)
  {
    if (action === "manual")
    {
      this.schoolManual = true;
      this.carerForm.get('schoolCode.manualEntry').setValue(true)
    }
  }

  gpAddressLookup(action: string){
    if (action === "manual")
    {
      this.gpManual = true;
      
      this.carerForm.get('gpCode.manualEntry').setValue(true)
    }
    else if (action === "auto")
    {
      this.gpManual = false;
      
      this.carerForm.get('gpCode.manualEntry').setValue(false)
    }
    
    // var addressVar;
    // console.log('GP address lookup starting with ' + postCode)
    // this.apiService.getGPAddress(postCode).subscribe((data)=>{ 
    // addressVar = data;
    // //console.log('GP address lookup')
    // //console.log(addressVar)
    // this.gpAddresses = addressVar
    // this.chRef.detectChanges();
    // })
    

  }

  onAddressSelected(value:number)
  {
    var address = this.carerAddresses;
    //console.log(address.length)
    this.carerForm.controls.addressLine1.setValue(address[value].line_1)
    this.carerForm.controls.addressLine2.setValue(address[value].line_2)
    this.carerForm.controls.addressLine3.setValue(address[value].line_3)
    this.carerForm.controls.city.setValue(address[value].town_or_city)
    this.carerForm.controls.county.setValue(address[value].county)
    
  }
  onGpAddressSelected(value:number)
  {
    // var address = this.gpAddresses;
    // //console.log(address.length)
    // this.carerForm.controls.gpAddressLine1.setValue(address[value].address.line1)
    // this.carerForm.controls.gpAddressLine2.setValue(address[value].address.line2)
    // this.carerForm.controls.gpAddressLine3.setValue(address[value].address.line3)
    // this.carerForm.controls.gpCity.setValue(address[value].address.city)
    // this.carerForm.controls.gpCounty.setValue(address[value].address.county)
    
  }
  onParentAddressSelected(value:number)
  {
    var address = this.parentAddresses;
    //console.log(address.length)
    this.carerForm.controls.parentAddressLine1.setValue(address[value].line_1)
    this.carerForm.controls.parentAddressLine2.setValue(address[value].line_2)
    this.carerForm.controls.parentAddressLine3.setValue(address[value].line_3)
    this.carerForm.controls.parentCity.setValue(address[value].town_or_city)
    this.carerForm.controls.parentCounty.setValue(address[value].county)
    
  }

  sameCarerAddressChecked(event)
  {
   
    if (event.checked === true)
    { this.sameCarerAddressCheck = true;
      var firstCarer = this.firstCarerForm.controls;
      this.carerForm.controls.addressLine1.setValue(firstCarer[0].get('addressLine1').value);
      this.carerForm.controls.addressLine2.setValue(firstCarer[0].get('addressLine2').value);
      this.carerForm.controls.addressLine3.setValue(firstCarer[0].get('addressLine3').value);
      this.carerForm.controls.city.setValue(firstCarer[0].get('city').value);
      this.carerForm.controls.county.setValue(firstCarer[0].get('county').value);
      this.carerForm.controls.postCode.setValue(firstCarer[0].get('postCode').value);
      this.carerForm.controls.gpCode.setValue(firstCarer[0].get('gpCode').value);

    }
    else{
      this.sameCarerAddressCheck = false;
    }

  }

  
  onSchoolAddressSelected(value:number)
  {
    var address = this.schoolAddresses;
    //console.log(address.length)
    this.carerForm.controls.schoolAddressLine1.setValue(address[value].line_1)
    this.carerForm.controls.schoolAddressLine2.setValue(address[value].line_2)
    this.carerForm.controls.schoolAddressLine3.setValue(address[value].line_3)
    this.carerForm.controls.schoolCity.setValue(address[value].town_or_city)
    this.carerForm.controls.schoolCounty.setValue(address[value].county)
    
  }

  public CalculateAge(): void {
    
  this.birthDate = this.dobControl.value;
  if (this.birthDate) {

    //Moment date to string
    var momentObj = moment(this.dobControl.value);
    var momentString = momentObj.format('DD/MM/YYYY');
    this.carerForm.get('dateOfBirth').setValue(momentString);
    
  // var timeDiff = Math.abs(Date.now() - new Date(this.birthDate).getTime());
  // console.log('now ' + Date.now());
  // console.log('differnce ' + timeDiff)
  // this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);

  this.age = moment().diff(momentObj, 'years');
  console.log("Years: " + this.age)

  var now = moment().diff(momentObj, 'days', true)
  console.log("Days: " + now)
  if (now < 0)
  {
    this.age = -1;
  }
  
  if (this.age < 0)
  { 
    var subDate = momentObj.subtract(100, 'years');
    
    var formatSubDate = subDate.format('DD/MM/YYYY');
    
    this.suggestDate = subDate.format('DD/MM/YYYY');
    console.log(this.suggestDate)
    this.carerForm.get('age').setValue(null);


  }
  else if (this.whoReferring === 'myself' && this.index === 0 && this.age < 13)
  {
    this.carerForm.get('age').setValue(null);
    console.log(this.carerForm.get('age').value)
  }
  else {
    this.suggestDate = null
    this.carerForm.get('age').setValue(this.age);
    console.log('This age: ' + this.age)
    }

  //Email
  if (this.age < 13)
  {
    console.log('Disable email - under 13')
    this.carerForm.controls.email.setValue(null);
    this.carerForm.controls.email.disable();

    this.carerForm.controls.telephone.setValue(null);
    this.carerForm.controls.telephone.disable();

    this.carerForm.controls.email.setValidators(null);
    this.carerForm.controls.telephone.setValidators(null);


  }
  else
  {    console.log('Enable email - not under 13')

    this.carerForm.controls.email.enable()
    this.carerForm.controls.telephone.enable();

    this.carerForm.controls.email.setValidators([Validators.required]);
    this.carerForm.controls.telephone.setValidators([Validators.required]);

    this.carerForm.controls.email.updateValueAndValidity();

    this.carerForm.controls.telephone.updateValueAndValidity();


  }
  //Military
  if (this.age < 16)
  {
    //this.carerForm.controls.armedForcesShow.setValue(null);
    //this.carerForm.controls.armedForcesShow.disable();

  }
  else
  {
    this.carerForm.controls.armedForcesShow.enable();

  }
  
  if (this.age < 18 )
  {
    
    this.carerForm.controls.eContact1Relationship.enable();
    this.carerForm.controls.eContact1Email.enable();
    this.carerForm.controls.eContact1Telephone.enable();
    this.carerForm.controls.eContact1Relationship.setValidators([Validators.required]);
    this.carerForm.controls.eContact1Telephone.setValidators([Validators.required]);


  
    this.carerForm.controls.reasonForReferral.enable();

    //this.carerForm.setValidators(this.atLeastOne('eContact1Email','eContact1Telephone'))
    //this.carerForm.controls.eContact1Relationship.setValidators([Validators.required])
    //this.carerForm.controls.eContact1Email.setValidators([Validators.required])
    //this.carerForm.controls.eContact1Telephone.setValidators([Validators.required])
    this.carerForm.controls.reasonForReferral.setValidators([Validators.required])
    this.carerForm.controls.educationStatus.setValidators([Validators.required])


    this.carerForm.controls.parentFirstName.enable();
    this.carerForm.controls.parentLastName.enable();
    if (this.carerForm.controls.eContact1Relationship.value === null && (this.relationship === 2328 || this.relationship === 2329)) 
    {
      this.carerForm.controls.eContact1Relationship.setValue(this.eContactRelationship);
      this.carerForm.controls.eContact1Email.setValue(this.eContactEmail);
      this.carerForm.controls.eContact1Telephone.setValue(this.eContactTelephone);

    }
    // if (this.age >15)
    // {
    //   console.log('15-18 - E-mail' );
    //   this.carerForm.controls.email.setValidators([Validators.required]);
    //   this.carerForm.controls.telephone.setValidators([Validators.required]);

    // }
    // else
    // {      console.log('Not 15-18' );
    //   this.carerForm.controls.telephone.setValidators(null);
    //   this.carerForm.controls.telephone.setValue(null)
    //   this.carerForm.controls.email.setValidators(null);
    //   this.carerForm.controls.telephone.setValidators(null);
    // }
  }
  
  else 

  
  { this.carerForm.controls.eContact1Relationship.setValidators(null);
    this.carerForm.controls.eContact1Telephone.setValidators(null);
    this.carerForm.controls.educationStatus.setValidators(null);

    this.carerForm.controls.eContact1Relationship.setValue(null);
    this.carerForm.controls.eContact1Email.setValue(null);
    this.carerForm.controls.eContact1Telephone.setValue(null);
    //this.carerForm.setValidators(this.atLeastOne('eContact1Email','eContact1Telephone'))
    //this.carerForm.setValidators(this.atLeastOne(null))

    this.carerForm.controls.reasonForReferral.setValue(null);

    this.carerForm.controls.parentFirstName.setValue(null);
    this.carerForm.controls.parentLastName.setValue(null);
    
    this.carerForm.controls.eContact1Relationship.disable();
    this.carerForm.controls.eContact1Email.disable();
    this.carerForm.controls.eContact1Telephone.disable();

    this.carerForm.controls.reasonForReferral.disable();

    this.carerForm.controls.parentFirstName.disable();
    this.carerForm.controls.parentLastName.disable();


  }
  // Education
  if (this.age > 24)
  {
    this.carerForm.controls.educationStatus.setValue(null);
    this.carerForm.controls.schoolCode.reset();
    this.schoolCtrl.setValue(null);
    this.schoolManual = false;
  }

  //Carer contact
  if ((this.age > 12 && this.whoReferring === 'myself') || (this.age > 12 && this.whoReferring === 'another' && (this.relationship === 2330 || this.relationship === 2358)))
    {
      this.carerForm.controls.requestContact.enable();
      this.carerForm.controls.requestContact.setValidators([Validators.required]);
    }
    else{
      console.log('Under 12, no contact consent shown')
      this.carerForm.controls.requestContact.disable();
      this.carerForm.controls.requestContact.setValidators(null);

    }
  this.carerForm.controls.email.updateValueAndValidity;

  this.carerForm.controls.requestContact.updateValueAndValidity;

  
  this.carerForm.controls.armedForcesShow.updateValueAndValidity();

  }
  this.chRef.detectChanges();

  }

  displayGpFn(gp: GP) {
    if (gp) { return gp.name; }
  }

  displaySchoolFn(s: School) {
    if (s) { return s.establishmenName; }
  }

  selectedGp(event: MatAutocompleteSelectedEvent): void {
    var thisGp: GP = event.option.value
    thisGp.manualEntry = false
    
    this.carerForm.controls.gpCode.setValue(thisGp)
  }

  selectedSchool(event: MatAutocompleteSelectedEvent): void {
    var thisSchool: School = event.option.value
    thisSchool.manualEntry = false;
    this.carerForm.controls.schoolCode.setValue(thisSchool)
  }

  armedForcesRadioChange($event: MatRadioChange) {
    console.log($event.source.name, $event.value);

    if ($event.value === true) {
      this.carerForm.controls.armedForcesMember.setValidators([Validators.required])
      this.carerForm.controls.armedForcesStatus.setValidators([Validators.required])
      this.carerForm.controls.armedForcesBranch.setValidators([Validators.required])
      
    }
    else
    {
      this.carerForm.controls.armedForcesMember.setValidators(null);
      this.carerForm.controls.armedForcesStatus.setValidators(null);
      this.carerForm.controls.armedForcesBranch.setValidators(null);
      this.carerForm.controls.armedForcesMember.setValue(null);
      this.carerForm.controls.armedForcesStatus.setValue(null);
      this.carerForm.controls.armedForcesBranch.setValue(null);
    }
    this.carerForm.controls.armedForcesMember.updateValueAndValidity();

}
armedForcesMemberChange(event: MatSelectChange) {
  if (event.value === 2459) {
      this.carerForm.controls.armedForcesStatus.setValidators(null);
      this.carerForm.controls.armedForcesBranch.setValidators(null);
      
      this.carerForm.controls.armedForcesStatus.setValue(null)
      this.carerForm.controls.armedForcesBranch.setValue(null);
      

  }
  else{
      this.carerForm.controls.armedForcesStatus.setValidators([Validators.required])
      this.carerForm.controls.armedForcesBranch.setValidators([Validators.required])
  }

}

oneducationStatusSelected(event: MatSelectChange) {
  
  if (event.value === 85 || event.value == 792)
  {

    //this.schoolCtrl.setValidators([Validators.required])
    this.carerForm.get('schoolCode.establishmenName').setValidators([Validators.required])
    this.carerForm.get('schoolCode.establishmenName').updateValueAndValidity();

  }
  else{
    this.schoolCtrl.setValidators(null)

    this.carerForm.get('schoolCode.establishmenName').setValidators(null)
    this.carerForm.controls.schoolCode.reset();
    this.schoolCtrl.setValue(null);
    this.schoolManual = false;
  }
}

dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (control?.value) {
      const today = new Date();
      const dateToCheck = new Date(control.value);
      if (dateToCheck > today) {
          return {'Invalid date': true}
      }
  }
  return null;
}
parentEContactChecked(event)
{
  if( event.checked === true)
  {
    this.carerForm.controls.eContact1Relationship.setValue(this.carerForm.get('parentFirstName').value + ' ' + this.carerForm.get('parentLastName').value)
    this.carerForm.controls.eContact1Telephone.setValue(this.carerForm.get('parentTelephone').value)
    this.carerForm.controls.eContact1Email.setValue(this.carerForm.get('parentEmail').value)

  }
}


parentAddressCheckBox(event){
  console.log(this.parentAddressChecked); //true or false
  console.log("index: " + this.index)
  if (event.checked === true)
  {
    console.log('true is called')
    this.carerForm.controls.parentPostCode.setValue(this.carerForm.get('postCode').value) 
    this.carerForm.controls.parentAddressLine1.setValue(this.carerForm.get('addressLine1').value)
    this.carerForm.controls.parentAddressLine2.setValue(this.carerForm.get('addressLine2').value)
    this.carerForm.controls.parentAddressLine3.setValue(this.carerForm.get('addressLine3').value)
    this.carerForm.controls.parentCity.setValue(this.carerForm.get('city').value)
    this.carerForm.controls.parentCounty.setValue(this.carerForm.get('county').value)

  // this.caredForForm.controls['county'].disable()
    this.carerForm.controls['parentPostCode'].disable()
  // this.onCarerAddressSelected(0);
  // for (let  carer  of this.carerForm.controls) {
  //    carer.get('firstName').value
  // }
  }
  else{
    console.log('false is called')
    // this.caredForForm.controls['addressLine1'].enable()
    // this.caredForForm.controls['addressLine2'].enable()
    // this.caredForForm.controls['addressLine3'].enable()
    // this.caredForForm.controls['city'].enable()
    // this.caredForForm.controls['county'].enable()
    this.carerForm.controls['parentPostCode'].enable()

  }
 }

 public getFormValidationErrors() {
  Object.keys(this.carerForm.controls).forEach(key => {
    const controlErrors: ValidationErrors = this.carerForm.get(key).errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach(keyError => {
       console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', +  controlErrors[keyError]);
      });
    }
  });
  console.log('Form state: ' + this.showErrors)


}

public atLeastOne(...fields: string[]) {
  if (this.age > 15)
  {
    return (fg: FormGroup): ValidationErrors | null => {
      return fields.some(fieldName => {
        const field = fg.get(fieldName).value;
        return true;
      })
        ? null
        : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
    };

  }else{
    return (fg: FormGroup): ValidationErrors | null => {
      return fields.some(fieldName => {
        const field = fg.get(fieldName).value;
        if (typeof field === 'number') return field && field >= 0 ? true : false;
        if (typeof field === 'string') return field && field.length > 0 ? true : false;
      })
        ? null
        : ({ atLeastOne: 'At least one field has to be provided.' } as ValidationErrors);
    };
  }
  
}

public noEmailChecked(event)
{
  if (event.checked === true)
  {
    this.carerForm.controls.email.setValue(null);
    this.carerForm.controls.email.disable();

    
  }
  else
  {
    this.carerForm.controls.email.enable(); 
   }
  
}

ngOnDestroy() {
    //remember to unsubscribe on destroy

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
      this.searchSubscription = null;
    }
  }

  
}
