import { FormControl, Validators, FormGroup, RequiredValidator } from '@angular/forms'
import { CarerRelationship } from './carerRelationship.model'

export class CarerRelationshipForm {
  carerGuid = new FormControl()
  cfGuid = new FormControl()
  relationship = new FormControl()
  
  

  constructor(
    carerRelationshp: CarerRelationship
  ) 
  {
    this.carerGuid.setValue(carerRelationshp.carerGuid);
    this.cfGuid.setValue(carerRelationshp.cfGuid);
    this.relationship.setValidators([Validators.required])
  }
}
