import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { YpsAssessmentComponent } from './yps-assessment/yps-assessment.component';
import { EventsComponent } from './events/events.component';


const routes: Routes = [
  { path: 'yps', component: YpsAssessmentComponent },
  { path: 'events', component: EventsComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
