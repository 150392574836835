import { Component, OnInit, Input, OnChanges, Output, ChangeDetectionStrategy, EventEmitter, ElementRef, ViewChild } from '@angular/core'
import { FormGroup, FormArray, FormControl } from '@angular/forms'
import {AppSettings} from '../AppSetting';
import { ApiService } from '../api.service';
import { ChangeDetectorRef } from '@angular/core';
import { findReadVarNames } from '@angular/compiler/src/output/output_ast';
import { Carer } from '../form-carer-details';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';
import {MatChipInputEvent} from '@angular/material/chips';

interface MfLookup {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-carer-relationship',
  templateUrl: './carer-relationship.component.html',
  styleUrls: ['./carer-relationship.component.scss'],
})
export class CarerRelationshipComponent implements OnInit, OnChanges {

  @Input() updateCount: number;
  @Input() carerRelationshipForm: FormArray
  @Input() index: number
  @Input() carerForm: FormArray
  @Input() caredForForm: FormArray
  @Output() deleteCaredFor: EventEmitter<number> = new EventEmitter()
  relationshipList: MfLookup[] = AppSettings.CarerRelationshipList; 



  constructor(private chRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    console.log("loading relationship table")
  }

  ngOnChanges() {
    // create header using child_id
    console.log(this.carerRelationshipForm.length);
  }

  getCarerName(guid: string)
  {
    for (let x of this.carerForm.controls)
      {
      var thisItem = x as FormGroup;
      
      if (thisItem.controls.guid.value === guid)
      { 
        var fName: string = thisItem.controls.firstName.value;
        var lName: string = thisItem.controls.lastName.value
        if (!fName || !lName)
        {
          return("Details not yet completed")
        
        }
        else{
          var fullName = fName + " " + lName
          return(fullName)
        }
        
      }

      }
    return("Name not entered");
  }

  getCaredForName(guid: string)
  {
    for (let x of this.caredForForm.controls)
    {
    var thisItem = x as FormGroup;
    
    if (thisItem.controls.guid.value === guid)
    {
      var fName: string = thisItem.controls.firstName.value;
      var lName: string = thisItem.controls.lastName.value
      if (!fName || !lName)
      {
        return("Details not yet completed")
      
      }
      else{
        var fullName = fName + " " + lName
        return(fullName)
      }
    }

    }
  return("Name not entered");
  }

}
