import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule, } from '@angular/material/button';
import {MatFormFieldModule, } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import { ReferralFormComponent } from './referral-form/referral-form.component';
import { FormCarerDetailsComponent } from './form-carer-details/form-carer-details.component';
import { FormCaredForComponent } from './form-cared-for/form-cared-for.component';
import { ReferralFormService } from './referral-form/referral-form.service';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { YourDetailsFormComponent } from './your-details-form/your-details-form.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { HttpClientModule } from '@angular/common/http';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter,MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import { CarerRelationshipComponent } from './carer-relationship/carer-relationship.component';
import { CarerConsentComponent } from './carer-consent/carer-consent.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ApiService } from './api.service';
import { FormCompleteComponent } from './form-complete/form-complete.component';
import {MatDialogModule} from '@angular/material/dialog';
import { OrdinalPipe } from './orginal.pipe';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { DialogComponentComponent } from './dialog-component/dialog-component.component';
import { RouterModule, Routes } from '@angular/router';
import { YpsAssessmentComponent } from './yps-assessment/yps-assessment.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { YpsAssessmentFormMacaComponent } from './yps-assessment-form-maca/yps-assessment-form-maca.component';
import { YpsAssessmentFormPanocComponent } from './yps-assessment-form-panoc/yps-assessment-form-panoc.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { EventsComponent } from './events/events.component';

const appRoutes: Routes = [
 
    { path: '**', component: ReferralFormComponent }
  ];

@NgModule({
  declarations: [
    AppComponent,
    ReferralFormComponent,
    FormCarerDetailsComponent,
    FormCaredForComponent,
    YourDetailsFormComponent,
    CarerRelationshipComponent,
    CarerConsentComponent,
    FormCompleteComponent,
    OrdinalPipe,
    DialogComponentComponent,
    YpsAssessmentComponent,
    YpsAssessmentFormMacaComponent,
    YpsAssessmentFormPanocComponent,
    EventsComponent
    
    
    
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDividerModule,
    MatListModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    HttpClientModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMomentDateModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    RxReactiveFormsModule,
    MatToolbarModule,
    MatButtonToggleModule,
    RouterModule.forRoot(appRoutes,
           { enableTracing: false }
           )

        
    
    
  ],
  providers: [ ReferralFormService, ApiService, MatDatepickerModule, {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}],
  bootstrap: [AppComponent], entryComponents: [DialogComponentComponent]
  
})
export class AppModule { }
